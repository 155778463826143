'use strict';

module.exports = {
  testUsers: [
    {
      username: 'admin@pricing.com',
      email: 'admin@pricing.com',
      password: 'password',
      roles: ['admin'],
      firstname: 'Anthony',
      surname: 'Admin',
    },
    {
      username: 'unit1@pricing.com',
      email: 'unit1@pricing.com',
      password: 'password',
      roles: ['unit-manager'],
      firstname: 'Absolute',
      surname: 'Unit',
    },
    {
      username: 'unit2@pricing.com',
      email: 'unit2@pricing.com',
      password: 'password',
      roles: ['unit-manager-admin'],
    },
    {
      username: 'category1@pricing.com',
      email: 'category1@pricing.com',
      password: 'password',
      roles: ['category-manager'],
      firstname: 'Cat',
      surname: 'Egory',
    },
    {
      username: 'category2@pricing.com',
      email: 'category2@pricing.com',
      password: 'password',
      roles: ['category-manager-admin'],
      firstname: 'Another',
      surname: 'Cat',
    },
    {
      username: 'pricingmanager1@pricing.com',
      email: 'pricingmanager1@pricing.com',
      password: 'password',
      roles: ['pricing-manager'],
      firstname: 'Pearce',
      surname: 'McManager',
    },
    {
      username: 'pricingmanager2@pricing.com',
      email: 'pricingmanager2@pricing.com',
      password: 'password',
      roles: ['pricing-manager-admin'],
      firstname: 'Peter',
      surname: 'McManager',
    },
    {
      username: 'pricing1@pricing.com',
      email: 'pricing1@pricing.com',
      password: 'password',
      roles: ['pricing-specialist'],
      firstname: 'Specialist',
      surname: 'Price',
    },
    {
      username: 'pricing2@pricing.com',
      email: 'pricing2@pricing.com',
      password: 'password',
      roles: ['pricing-specialist-admin'],
      firstname: 'Specialist',
      surname: 'Price The Second',
    },
    {
      username: 'wholesale@pricing.com',
      email: 'wholesale@pricing.com',
      password: 'password',
      roles: ['wholesale-pricing-analyst'],
      firstname: 'Sally',
      surname: 'Wholesale',
    },
    {
      username: 'wholesalemanager@pricing.com',
      email: 'wholesalemanager@pricing.com',
      password: 'password',
      roles: ['wholesale-pricing-manager'],
      firstname: 'Sally',
      surname: 'Mac',
    },
    {
      username: 'unitandcategorymanager@pricing.com',
      email: 'unitandcategorymanager@pricing.com',
      password: 'password',
      roles: ['unit-manager', 'category-manager'],
      firstname: 'Absolute',
      surname: 'Lee',
    },
  ],
};
