import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        VDialog,
        {
          attrs: {
            "content-class": _vm.dialogClass,
            persistent: "",
            "max-width": "100rem",
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "pl-1 pr-1" },
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("workPackages.transferWorkpackage")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                VIcon,
                {
                  staticClass: "close-button",
                  attrs: { color: "grey" },
                  on: { click: _vm.emitCancel },
                },
                [_vm._v("close")]
              ),
              _vm._v(" "),
              _c(VDivider),
              _vm._v(" "),
              !_vm.showConfirmationScreen
                ? [
                    _c(
                      VRow,
                      { staticClass: "main-section" },
                      [
                        _c(
                          VCol,
                          { staticClass: "sections", attrs: { cols: "3" } },
                          [
                            _c(VRow, { staticClass: "heading" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "transferWorkpackage.headings.itemstoTransfer"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fieldsWithCollections, function (item) {
                              return _c(
                                VRow,
                                { key: item.id, staticClass: "transfer-item" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedFields,
                                        expression: "selectedFields",
                                      },
                                    ],
                                    staticClass: "primary-input",
                                    attrs: {
                                      id: item.id,
                                      type: "checkbox",
                                      disabled: item.disabled,
                                    },
                                    domProps: {
                                      value: item,
                                      checked: Array.isArray(_vm.selectedFields)
                                        ? _vm._i(_vm.selectedFields, item) > -1
                                        : _vm.selectedFields,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedFields,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = item,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedFields = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedFields = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedFields = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { attrs: { for: item.id } }, [
                                    _vm._v(_vm._s(item.text)),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          VCol,
                          {
                            staticClass: "sections scrollable",
                            attrs: { cols: "5" },
                          },
                          [
                            _c(VRow, { staticClass: "heading" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("transferWorkpackage.headings.from")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              VRow,
                              { staticClass: "from-item" },
                              [
                                _c(VCol, { staticClass: "heading" }, [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "transferWorkpackage.headings.units"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _c(
                                      "label",
                                      { attrs: { for: "allUnits" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "transferWorkpackage.headings.all"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      staticClass: "primary-input",
                                      attrs: {
                                        id: "allUnits",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.allInputChecked,
                                      },
                                      on: { change: _vm.allInputChange },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(VCol, { staticClass: "heading" }, [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "transferWorkpackage.headings.categories"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _c(
                                      "label",
                                      { attrs: { for: "allCategories" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "transferWorkpackage.headings.all"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      staticClass: "primary-input",
                                      attrs: {
                                        id: "allCategories",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.allInputChecked,
                                      },
                                      on: { change: _vm.allInputChange },
                                    }),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              VRow,
                              { staticClass: "from-item border" },
                              [
                                _c(
                                  VCol,
                                  {
                                    staticClass: "options",
                                    attrs: { cols: "6" },
                                  },
                                  _vm._l(_vm.workpackageUnits, function (item) {
                                    return _c("span", { key: item.id }, [
                                      _c("label", { attrs: { for: item.id } }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "primary-input",
                                        attrs: {
                                          id: item.id,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          value: item.id,
                                          checked: _vm.unitChecked(item.id),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.unitChange(item.id)
                                          },
                                        },
                                      }),
                                    ])
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  VCol,
                                  {
                                    staticClass: "options",
                                    attrs: { cols: "6" },
                                  },
                                  _vm._l(
                                    _vm.workpackageCategories,
                                    function (item) {
                                      return _c("span", { key: item.id }, [
                                        _c(
                                          "label",
                                          { attrs: { for: item.id } },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          staticClass: "primary-input",
                                          attrs: {
                                            id: item.id,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            value: {
                                              id: item.id,
                                              parentId: item.parentId,
                                            },
                                            checked: _vm.categoryChecked({
                                              id: item.id,
                                              parentId: item.parentId,
                                            }),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.categoryChange({
                                                id: item.id,
                                                parentId: item.parentId,
                                              })
                                            },
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          VCol,
                          { staticClass: "sections", attrs: { cols: "3" } },
                          [
                            _c(VRow, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("transferWorkpackage.headings.to")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              VRow,
                              [
                                _c(VAutocomplete, {
                                  staticClass: "workpackage-dropdown",
                                  attrs: {
                                    dense: "",
                                    "return-object": "",
                                    "hide-details": "",
                                    solo: "",
                                    flat: "",
                                    items:
                                      _vm.workpackagesThatCanReceiveTransfer,
                                    "item-text": "description",
                                    "item-value": "description",
                                    placeholder: _vm.$t(
                                      "transferWorkpackage.selectWorkpackage"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "text" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      props.item.description
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4148248570
                                  ),
                                  model: {
                                    value: _vm.destinationWorkpackage,
                                    callback: function ($$v) {
                                      _vm.destinationWorkpackage = $$v
                                    },
                                    expression: "destinationWorkpackage",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.willEngineRun
                              ? _c(VRow, [
                                  _c(
                                    "span",
                                    { staticClass: "warning-message" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "transferWorkpackage.warnAboutEngineRun"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "error--text mr-2 d-flex flex-column validation-errors",
                      },
                      [
                        _vm.validationErrors
                          ? _vm._l(
                              _vm.validationErrors,
                              function (errorMessage) {
                                return _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(errorMessage) +
                                      "\n            "
                                  ),
                                ])
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(VDivider),
                    _vm._v(" "),
                    _c(
                      VCardActions,
                      [
                        _c(VSpacer),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.dataValidationInProgress,
                                expression: "dataValidationInProgress",
                              },
                            ],
                            staticClass: "validating-transfer-message",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "transferWorkpackage.validatingTransfer"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            attrs: {
                              color: "primary",
                              small: "",
                              text: "",
                              depressed: "",
                            },
                            on: { click: _vm.emitCancel },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "save",
                            attrs: {
                              color: "success",
                              disabled:
                                !_vm.fieldsAndWorkpackageChosen ||
                                _vm.transferBlocked ||
                                _vm.dataValidationInProgress,
                              loading: _vm.dataValidationInProgress,
                              small: "",
                              depressed: "",
                            },
                            on: { click: _vm.confirmTransferring },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.transfer")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      VCardText,
                      { staticClass: "body pt-2 ma-0 confirmation" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("transferWorkpackage.areYouSure"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("transferWorkpackage.note"))),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("transferWorkpackage.cannotBeUndone"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("transferWorkpackage.runEngineFirst"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "transferWorkpackage.warnAboutHierarchyTransfer"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "pt-2 confirm-btns" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "save pa-0",
                                attrs: {
                                  small: "",
                                  depressed: "",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.emitOk()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("actions.continue")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              VBtn,
                              {
                                staticClass: "pa-0",
                                attrs: {
                                  small: "",
                                  depressed: "",
                                  text: "",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.showConfirm = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("actions.cancel")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      VCardActions,
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          VAlert,
                          {
                            staticClass: "mb-0 mr-0 alert-banner",
                            attrs: {
                              value: true,
                              color: _vm.colours.alertsIconColor,
                              icon: "warning",
                              "min-width": "100rem",
                            },
                          },
                          [
                            _c("span", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("transferWorkpackage.warning")
                                  ) + ": "
                                ),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("transferWorkpackage.dataReplaced")
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }