<template>
  <v-btn
    color="primary"
    x-small
    depressed
    :loading="recalculatingAll"
    :disabled="isRecalculateDisabled"
    class="mr-2 btn-refresh white--text hierarchy-level-item__recalculate-btn"
    @click="runEngineForAllScenarios"
  >
    {{ $t('actions.recalculateAll') }}
    <v-icon v-once size="2rem">mdi-refresh</v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('gridView', ['calculatingScenarioResults', 'recalculatingAll']),
    ...mapGetters('context', ['isWholesaleManager', 'isWholesaleAnalyst', 'isAdminLoggedIn']),

    isRecalculateDisabled() {
      return this.isPermissionDenied || this.calculatingScenarioResults || this.recalculatingAll;
    },

    isPermissionDenied() {
      return (this.isWholesaleManager || this.isWholesaleAnalyst) && !this.isAdminLoggedIn;
    },
  },
  async created() {
    await this.checkRecalculatingAll();
  },
  methods: {
    ...mapActions('context', ['logout', 'resetState']),
    ...mapActions('gridView', ['runEngineForAllScenarios', 'checkRecalculatingAll']),
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  // avoid rem, want button to be exactly the same regardless of parent container
  font-size: 14px !important;
  height: 24px;
  width: 140px;
}
</style>
