import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "40rem" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VForm,
                { ref: "form" },
                [
                  _c(VCardText, { staticClass: "header-text" }, [
                    _vm._v(
                      _vm._s(_vm.$t("unsavedChangesDialog.headerConfirmText"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCardText, { staticClass: "body" }, [
                    _c("p", { staticClass: "lead" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("unsavedChangesDialog.unsavedChangeStatement")
                        ) + "."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "lead" }, [
                      _vm._v(
                        _vm._s(_vm.$t("unsavedChangesDialog.optionPrompt")) +
                          "."
                      ),
                    ]),
                    _vm._v(" "),
                    !_vm.localSaveAllowed
                      ? _c("p", { staticClass: "lead error-message" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "unsavedChangesDialog.saveDisabledMessage"
                                )
                              ) +
                              ".\n          "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    VCardActions,
                    { staticClass: "btn-group" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "cancel-btn",
                          attrs: { text: "", outlined: "", depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog(_vm.cancel)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            id: "unsaved-discard-button",
                            text: "",
                            outlined: "",
                            depressed: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog(_vm.discard)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.discard")))]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            id: "unsaved-save-button",
                            depressed: "",
                            disabled: !_vm.localSaveAllowed,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog(_vm.save)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.save")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }