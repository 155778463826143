<template>
  <div class="diagnostics">
    <div class="diagnostics__container">
      <div class="diagnostics__container--selector">
        <!-- update storegroups for grids from hardcoded storegroups -->
        <template v-if="canDisplayGridsStoregroupsUpdate">
          <div class="d-flex py-4 justify-start">
            <wholesale-storegroups-update-button />
          </div>
        </template>
        <div>{{ $t('diagnostics.diagnosticToRun') }}:</div>
        <v-autocomplete
          dense
          return-object
          hide-details
          :items="diagnostics"
          item-text="description"
          item-value="description"
          :placeholder="$t('diagnostics.diagnostic')"
          @input="updateSelectedDiagnostic"
        >
          <template v-slot:item="props">
            <span class="text">
              {{ props.item.description }}
            </span>
          </template>
        </v-autocomplete>
        <v-checkbox
          v-model="shouldExport"
          :label="$t('actions.download')"
          :disabled="!get(selectedDiagnostic, 'canExport', false)"
        />
        <v-checkbox
          v-model="shouldFix"
          :label="$t('actions.fix')"
          :disabled="!get(selectedDiagnostic, 'canFix', false)"
        />
        <v-btn
          class="diagnostics__container--selector__button"
          color="primary"
          small
          depressed
          :disabled="!selectedDiagnostic"
          :loading="loading"
          @click="runDiagnostic"
        >
          {{ $t('actions.submit') }}
        </v-btn>
      </div>
      <pricing-json-editor
        :error="diagnosticError"
        :response="diagnosticResponse"
        class="diagnostics__container--editor"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import to from 'await-to-js';
import { get, omit, keys } from 'lodash';
import { mapState } from 'vuex';
import downloadXlsxFile from '../../store/utils/download-xlsx-file';
import featureFlagsMixin from '../../mixins/featureFlags';
import { displayGridsStoregroupsUpdate } from '@enums/feature-flags';

export default {
  mixins: [featureFlagsMixin],

  data() {
    return {
      selectedDiagnostic: null,
      diagnostics: [
        {
          description: this.$t('diagnostics.attributesMismatch'),
          urlSuffix: 'attributes-mismatch',
          canExport: true,
          exportDataKey: 'attributeMismatchesAcrossCollections',
        },
        {
          description: this.$t('diagnostics.aggregatedScenarioResultsForDeletedProducts'),
          urlSuffix: 'aggregated-scenario-results-deleted-products',
          canExport: false,
          canFix: true,
        },
        {
          description: this.$t('diagnostics.competitorPriceMismatchesAcrossCollections'),
          urlSuffix: 'competitor-price-mismatches',
          canExport: false,
          canFix: false,
        },
        {
          description: this.$t('diagnostics.scenarioResultsWithBrokenErrors'),
          urlSuffix: 'scenario-results-with-incorrect-errors',
          canExport: false,
          canFix: true,
        },
        {
          description: this.$t('diagnostics.productsWithIncorrectGoLiveDate'),
          urlSuffix: 'products-with-incorrect-go-live-date',
          canExport: false,
          canFix: false,
        },
        {
          description: this.$t('diagnostics.scenarioMetadataWithOrphanedPricingGroups'),
          urlSuffix: 'scenario-metadata-with-orphaned-pricing-groups',
          canExport: false,
          canFix: true,
        },
      ],
      shouldExport: false,
      shouldFix: false,
      diagnosticResponse: {},
      diagnosticError: {},
      loading: false,
    };
  },
  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),

    canDisplayGridsStoregroupsUpdate() {
      return this.isFeatureFlagEnabled(displayGridsStoregroupsUpdate);
    },
  },
  methods: {
    get,
    async runDiagnostic() {
      this.loading = true;
      let axiosMethod = axios.get;
      if (this.shouldFix) axiosMethod = axios.post;
      const [error, response] = await to(
        axiosMethod(
          `/api/diagnostics/workpackage/${this.selectedWorkpackage._id}/${
            this.selectedDiagnostic.urlSuffix
          }`
        )
      );
      this.loading = false;

      this.diagnosticError = error ? { ...omit(error.response, ['request']) } || {} : {};

      if (this.shouldExport) {
        const filename = `${this.selectedDiagnostic.description
          .toLowerCase()
          .split(' ')
          .join('_')}.xlsx`;
        let dataPath = `data`;
        if (this.selectedDiagnostic.exportDataKey)
          dataPath = `${dataPath}.${this.selectedDiagnostic.exportDataKey}`;
        const dataArray = get(response, dataPath, []);
        const headers = keys(get(dataArray, 0));
        downloadXlsxFile({ headers: [headers], rows: dataArray }, filename);
      }
      this.diagnosticResponse = get(response, 'data') || {};
    },

    updateSelectedDiagnostic(diagnostic) {
      this.selectedDiagnostic = diagnostic;
      this.shouldExport = false;
      this.shouldFix = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.diagnostics {
  height: 100%;
  width: 100%;
  background-color: white;
  font-size: 1.4rem;

  &__container {
    padding-top: 1rem;
    display: flex;

    &--selector {
      width: 50%;
      padding-left: 1rem;
      &__button {
        margin-top: 1rem;
      }
    }
    &--editor {
      padding-left: 1rem;
    }
  }

  ::v-deep {
    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin: unset;
    }
  }
}
</style>
