<template>
  <span>
    <v-btn
      color="primary"
      small
      depressed
      class="pa-0"
      :loading="busyImportingEngineInput"
      @click.stop="initiateModal()"
    >
      {{ $t('engineInputs.upload.uploadButton') }}
      <v-icon small>$import</v-icon>
    </v-btn>
    <v-dialog v-model="uploadDialog" width="500">
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('engineInputs.upload.dialogHeading') }}
        </v-card-title>
        <file-upload
          v-if="isInitial"
          :upload-field-name="uploadFieldName"
          :is-saving="isSaving"
          source="inputs"
          @save="save"
        />
        <div v-if="isValidated">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="dialog-copy">
                    {{ $t('engineInputs.upload.inputsReceived') }}:
                    <b>{{ counts.articlesReceived }}</b>
                    <br />
                    {{ $t('attributes.upload.confirmUpload') }}
                    <div v-if="templateFutureCostDisabled" class="future-cost-warning">
                      <v-icon class="warning-icon" size="1.5rem" :color="alertsIconColor">
                        warning
                      </v-icon>
                      <b>{{ $t('engineInputs.upload.futureCostsWillNotBeUpdated') }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" outlined @click="closeModal()">{{ $t('actions.cancel') }}</v-btn>
            <v-btn
              class="primary"
              :disabled="isUploading"
              :loading="busyImportingEngineInput"
              @click="confirm()"
            >
              {{ $t('actions.upload') }}
            </v-btn>
          </v-card-actions>
        </div>
        <div v-if="isUploading">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="v-input">
                    {{ $t('attributes.upload.uploadingState') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
        <div
          v-if="
            isFailed &&
              !(
                duplicateKeysError ||
                incorrectTSGsError ||
                productKeysWithInvalidPromoDiscount ||
                productKeysWithEffectiveGoLiveDateErrors ||
                productKeysWithExpiryGoLiveDateErrors ||
                productKeysWithExpiryEffectiveDateErrors
              )
          "
        >
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div v-if="uploadError.tooManyRows" class="flex xs12">
                  <div class="v-input">
                    {{ $t('engineInputs.upload.tooManyRows', { maxRows }) }}
                  </div>
                </div>
                <div v-else class="flex xs12">
                  <div class="v-input">
                    {{ $t('attributes.upload.genericError') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="closeModal()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
        <!-- FEATURE_FLAG: display zones-related validation errors -->
        <feature-toggle :toggle="useZones">
          <div v-if="duplicateKeysError || incorrectTSGsError">
            <v-card-text>
              <div class="container grid-list-md">
                <div class="layout wrap">
                  <upload-error-card
                    v-if="duplicateKeysError"
                    :error-message="$t('engineInputs.upload.conflictedToolStoreGroupKeys')"
                    :details="uploadError.duplicateProductKeys"
                    unique-key-prefix="conflictToolStoreGroupKeys"
                  />
                  <upload-error-card
                    v-if="incorrectTSGsError"
                    :error-message="$t('engineInputs.upload.incorrectToolStoreGroups')"
                    :details="uploadError.productKeysWithIncorrectTSGs"
                    unique-key-prefix="incorrectToolStoreGroups"
                  />
                </div>
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn class="primary" @click="closeModal()">{{ $t('actions.close') }}</v-btn>
            </v-card-actions>
          </div>
        </feature-toggle>
        <div
          v-if="
            productKeysWithEffectiveGoLiveDateErrors ||
              productKeysWithExpiryGoLiveDateErrors ||
              productKeysWithExpiryEffectiveDateErrors ||
              productKeysWithInvalidPromoDiscount
          "
        >
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <upload-error-card
                  v-if="productKeysWithEffectiveGoLiveDateErrors"
                  :error-message="$t('engineInputs.upload.effectiveGoLiveDateErrors')"
                  :details="uploadError.productKeysWithEffectiveGoLiveDateErrors"
                  unique-key-prefix="effectiveGoLiveDateErrors"
                />
                <upload-error-card
                  v-if="productKeysWithExpiryGoLiveDateErrors"
                  :error-message="$t('engineInputs.upload.expiryGoLiveDateErrors')"
                  :details="uploadError.productKeysWithExpiryGoLiveDateErrors"
                  unique-key-prefix="expiryGoLiveDateErrors"
                />
                <upload-error-card
                  v-if="productKeysWithExpiryEffectiveDateErrors"
                  :error-message="$t('engineInputs.upload.expiryEffectiveDateErrors')"
                  :details="uploadError.productKeysWithExpiryEffectiveDateErrors"
                  unique-key-prefix="expiryEffectiveDateErrors"
                />
                <upload-error-card
                  v-if="productKeysWithInvalidPromoDiscount"
                  :error-message="$t('engineInputs.upload.invalidPromoDiscount')"
                  :details="uploadError.productKeysWithInvalidPromoDiscount"
                  unique-key-prefix="invalidPromoDiscount"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="closeModal()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { isEmpty, get, mapValues } from 'lodash';
import states from '@enums/upload-states';
import { useZones, allowTemplateIntentionCostChange } from '@enums/feature-flags';
import mandatoryEngineInputs from '@enums/mandatory-engine-inputs';
import featureFlagsMixin from '../../../../mixins/featureFlags';
import colours from '../../../../ow-colors';

export default {
  mixins: [featureFlagsMixin],
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      uploadDialog: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: states.initial,
      uploadFieldName: 'file',
      useZones,
      allowTemplateIntentionCostChange,
      alertsIconColor: colours.alertsIconColor,
    };
  },
  computed: {
    ...mapState('workpackageProducts', ['counts', 'uploadData', 'busyImportingEngineInput']),
    ...mapState('clientConfig', ['uploadConfig']),
    ...mapGetters('workpackages', ['isSelectedWorkpackageMaster']),

    maxRows() {
      return this.uploadConfig.attributeUpload.maxRows;
    },
    isInitial() {
      return this.currentStatus === states.initial;
    },
    isSaving() {
      return this.currentStatus === states.saving;
    },
    isValidated() {
      return this.currentStatus === states.validated;
    },
    isFailed() {
      return this.currentStatus === states.failed;
    },
    duplicateKeysError() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'duplicateProductKeys', []))
      );
    },
    incorrectTSGsError() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysWithIncorrectTSGs', []))
      );
    },
    productKeysWithEffectiveGoLiveDateErrors() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysWithEffectiveGoLiveDateErrors', []))
      );
    },
    productKeysWithExpiryGoLiveDateErrors() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysWithExpiryGoLiveDateErrors', []))
      );
    },
    productKeysWithInvalidPromoDiscount() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysWithInvalidPromoDiscount', []))
      );
    },
    productKeysWithExpiryEffectiveDateErrors() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysWithExpiryEffectiveDateErrors', []))
      );
    },
    isUploading() {
      return this.currentStatus === states.uploading;
    },
    templateFutureCostDisabled() {
      // display a warning message if attempting to edit master workpackage and that feature is disabled
      return (
        this.isSelectedWorkpackageMaster &&
        !this.isFeatureFlagEnabled(allowTemplateIntentionCostChange)
      );
    },
  },
  methods: {
    ...mapActions('workpackageProducts', ['importEngineInputs', 'importEngineInputsConfirm']),
    ...mapActions('engineInputs', [
      'doesProductExistWithMissingEngineInputs',
      'doesProductExistWithInvalidEngineInputs',
    ]),

    initiateModal() {
      this.uploadDialog = true;
      this.reset();
    },
    closeModal() {
      this.uploadDialog = false;
      this.reset();
    },
    save(formData) {
      this.currentStatus = states.saving;

      const translationMap = {
        ...mapValues(mandatoryEngineInputs, path => this.$t(path)),
        expiryDate: this.$t('engineInputs.editor.expiryDate'),
        effectiveDate: this.$t('engineInputs.editor.effectiveDate'),
      };
      return this.importEngineInputs({
        dataFile: formData,
        params: this.params,
        translationMap,
      })
        .then(() => {
          this.currentStatus = states.validated;
        })
        .catch(err => {
          this.uploadError = err;
          this.currentStatus = states.failed;
        });
    },
    confirm() {
      this.currentStatus = states.uploading;
      return this.importEngineInputsConfirm()
        .then(() => {
          this.closeModal();
          this.doesProductExistWithMissingEngineInputs();
          this.doesProductExistWithInvalidEngineInputs();
          this.reset();
        })
        .catch(err => {
          this.uploadError = err.response;
          this.currentStatus = states.failed;
        });
    },
    reset() {
      this.currentStatus = states.initial;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-copy {
  font-size: 1.6rem;
}

.future-cost-warning {
  padding-top: 1.5rem;
  font-size: 1.3rem;
}

.warning-icon {
  padding-bottom: 0.3rem;
}
</style>
