<template>
  <v-layout row justify-center>
    <v-dialog v-model="isOpen" :content-class="dialogClass" persistent max-width="100rem">
      <v-card class="pl-1 pr-1">
        <v-card-title class="headline">
          {{ $t('workPackages.transferWorkpackage') }}
        </v-card-title>
        <v-icon class="close-button" color="grey" @click="emitCancel">close</v-icon>
        <v-divider />
        <template v-if="!showConfirmationScreen">
          <v-row class="main-section">
            <v-col class="sections" cols="3">
              <v-row class="heading">{{
                $t('transferWorkpackage.headings.itemstoTransfer')
              }}</v-row>
              <v-row v-for="item in fieldsWithCollections" :key="item.id" class="transfer-item">
                <input
                  :id="item.id"
                  v-model="selectedFields"
                  type="checkbox"
                  :disabled="item.disabled"
                  :value="item"
                  class="primary-input"
                />
                <label :for="item.id">{{ item.text }}</label>
              </v-row>
            </v-col>
            <v-col class="sections scrollable" cols="5">
              <v-row class="heading">{{ $t('transferWorkpackage.headings.from') }}</v-row>
              <v-row class="from-item">
                <v-col class="heading">
                  <span>
                    <strong>{{ $t('transferWorkpackage.headings.units') }}</strong>
                  </span>
                  <span>
                    <label for="allUnits">{{ $t('transferWorkpackage.headings.all') }}</label>
                    <input
                      id="allUnits"
                      type="checkbox"
                      :checked="allInputChecked"
                      class="primary-input"
                      @change="allInputChange"
                    />
                  </span>
                </v-col>
                <v-col class="heading">
                  <span>
                    <strong>{{ $t('transferWorkpackage.headings.categories') }}</strong>
                  </span>
                  <span>
                    <label for="allCategories">{{ $t('transferWorkpackage.headings.all') }}</label>
                    <input
                      id="allCategories"
                      type="checkbox"
                      :checked="allInputChecked"
                      class="primary-input"
                      @change="allInputChange"
                    />
                  </span>
                </v-col>
              </v-row>
              <v-row class="from-item border">
                <v-col class="options" cols="6">
                  <span v-for="item in workpackageUnits" :key="item.id">
                    <label :for="item.id">{{ item.name }}</label>
                    <input
                      :id="item.id"
                      type="checkbox"
                      :value="item.id"
                      :checked="unitChecked(item.id)"
                      class="primary-input"
                      @change="unitChange(item.id)"
                    />
                  </span>
                </v-col>
                <v-col class="options" cols="6">
                  <span v-for="item in workpackageCategories" :key="item.id">
                    <label :for="item.id">{{ item.name }}</label>
                    <input
                      :id="item.id"
                      type="checkbox"
                      :value="{ id: item.id, parentId: item.parentId }"
                      :checked="categoryChecked({ id: item.id, parentId: item.parentId })"
                      class="primary-input"
                      @change="categoryChange({ id: item.id, parentId: item.parentId })"
                    />
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="sections" cols="3">
              <v-row>{{ $t('transferWorkpackage.headings.to') }}</v-row>
              <v-row>
                <v-autocomplete
                  v-model="destinationWorkpackage"
                  dense
                  return-object
                  hide-details
                  solo
                  flat
                  :items="workpackagesThatCanReceiveTransfer"
                  item-text="description"
                  item-value="description"
                  :placeholder="$t('transferWorkpackage.selectWorkpackage')"
                  class="workpackage-dropdown"
                >
                  <template v-slot:item="props">
                    <span class="text">
                      {{ props.item.description }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row v-if="willEngineRun"
                ><span class="warning-message">{{
                  $t('transferWorkpackage.warnAboutEngineRun')
                }}</span></v-row
              >
            </v-col>
          </v-row>
          <div class="error--text mr-2 d-flex flex-column validation-errors">
            <template v-if="validationErrors">
              <span v-for="errorMessage in validationErrors">
                {{ errorMessage }}
              </span>
            </template>
          </div>

          <v-divider />
          <v-card-actions>
            <v-spacer />
            <span v-show="dataValidationInProgress" class="validating-transfer-message">
              {{ $t('transferWorkpackage.validatingTransfer') }}
            </span>
            <v-btn color="primary" small text depressed @click="emitCancel">
              {{ $t('actions.cancel') }}
            </v-btn>
            <v-btn
              color="success"
              class="save"
              :disabled="!fieldsAndWorkpackageChosen || transferBlocked || dataValidationInProgress"
              :loading="dataValidationInProgress"
              small
              depressed
              @click="confirmTransferring"
            >
              {{ $t('actions.transfer') }}
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-text class="body pt-2 ma-0 confirmation">
            <span>{{ $t('transferWorkpackage.areYouSure') }}</span>
            <br />
            <strong>{{ $t('transferWorkpackage.note') }}</strong>
            <br />
            <strong>{{ $t('transferWorkpackage.cannotBeUndone') }}</strong>
            <br />
            <strong>{{ $t('transferWorkpackage.runEngineFirst') }}</strong>
            <br />
            <strong>{{ $t('transferWorkpackage.warnAboutHierarchyTransfer') }}</strong>
            <div class="pt-2 confirm-btns">
              <v-btn small depressed class="save pa-0" color="primary" @click="emitOk()">
                {{ $t('actions.continue') }}
              </v-btn>
              <br />
              <v-btn small depressed text class="pa-0" color="primary" @click="showConfirm = false">
                {{ $t('actions.cancel') }}
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="pa-0 ma-0">
            <v-alert
              :value="true"
              :color="colours.alertsIconColor"
              icon="warning"
              min-width="100rem"
              class="mb-0 mr-0 alert-banner"
            >
              <span>
                <strong>{{ $t('transferWorkpackage.warning') }}: </strong>
                {{ $t('transferWorkpackage.dataReplaced') }}
              </span>
            </v-alert>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { mapState, mapGetters, mapActions } from 'vuex';
import { isEmpty, get } from 'lodash';
import colours from '../../../ow-colors';
import overwriteFields from '@enums/overwrite-fields-with-collections';
import workpackageTypes from '@enums/workpackage-types';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    workpackageToTransfer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      destinationWorkpackage: null,
      selectedFields: [],
      selectedUnits: [],
      selectedCategories: [],
      units: [],
      categories: [],
      overwriteFields,
      fieldSearchInput: null,
      showConfirm: false,
      colours,
      dataValidationInProgress: false,
      validationErrors: null,
    };
  },
  computed: {
    ...mapState('workpackages', ['workpackages']),
    ...mapGetters('workpackages', ['isAnyWorkpackageInTransferList']),
    ...mapGetters('hierarchy', ['getHierarchyForWorkpackage']),

    allInputChecked() {
      return this.selectedCategories.length === this.workpackageCategories.length;
    },

    willEngineRun() {
      return this.overwriteFields.some(
        field => this.selectedFields.find(sf => sf.id === field.id) && field.runEngine
      );
    },

    selectAllCategories: {
      get() {
        const categories = this.workpackageCategories;
        return categories ? this.selectedCategories.length === categories.length : false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.workpackageCategories.forEach(function(category) {
            selected.push(category.id);
          });
        }

        this.selectedCategories = selected;
      },
    },

    selectAllUnits: {
      get() {
        const units = this.workpackageUnits;
        return units ? this.selectedUnits.length === units.length : false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.workpackageUnits.forEach(function(unit) {
            selected.push(unit.id);
          });
        }

        this.selectedUnits = selected;
      },
    },

    fieldsWithCollections() {
      return overwriteFields.map(overwriteField => {
        return {
          id: overwriteField.id,
          text: this.$t(overwriteField.translationKey),
          disabled:
            overwriteField.transferDisabled ||
            !overwriteField.allowedSources.includes(this.workpackageToTransfer.type),
        };
      });
    },

    workpackagesThatCanReceiveTransfer() {
      const oppositeType =
        this.workpackageToTransfer.type === workpackageTypes.master
          ? workpackageTypes.workpackage
          : workpackageTypes.master;
      return this.workpackages.filter(
        wp =>
          wp.storeFormat === get(this.workpackageToTransfer, 'storeFormat', null) &&
          wp.type === oppositeType
      );
    },

    workpackageUnits() {
      const units =
        this.getHierarchyForWorkpackage(
          {
            level: 1,
          },
          this.workpackageToTransfer._id
        ).map(unit => ({
          id: unit.levelEntryKey,
          name: unit.levelEntryDescription,
        })) || [];
      return units;
    },

    workpackageCategories() {
      const categories =
        this.getHierarchyForWorkpackage(
          {
            level: 2,
          },
          this.workpackageToTransfer._id
        ).map(category => ({
          id: category.levelEntryKey,
          name: category.levelEntryDescription,
          parentId: category.parentId,
        })) || [];
      return categories;
    },

    fieldsAndWorkpackageChosen() {
      return (
        !isEmpty(this.selectedFields) &&
        !isEmpty(this.destinationWorkpackage) &&
        this.selectedCategories.length > 0
      );
    },

    showConfirmationScreen() {
      return this.showConfirm === true;
    },
    dialogClass() {
      return `transfer-dialog ${this.showConfirmationScreen ? 'alert-border' : ''}`;
    },
    transferBlocked() {
      const ids = [];
      if (this.workpackageToTransfer) {
        ids.push(this.workpackageToTransfer._id);
      }
      if (this.destinationWorkpackage) {
        ids.push(this.destinationWorkpackage._id);
      }
      // blocking transfer if either source or destination workpackage is in transfer list
      return this.isAnyWorkpackageInTransferList(ids);
    },
  },
  methods: {
    ...mapActions('workpackages', ['validateDataBeforeTransferring']),

    resetDialogData() {
      this.destinationWorkpackage = null;
      this.selectedFields.splice(0);
      this.selectedCategories = [];
      this.selectedUnits = [];
      this.validationErrors = null;
    },

    emitOk() {
      this.$emit('onOk', {
        selectedFields: this.selectedFields,
        destinationWorkpackage: this.destinationWorkpackage,
        selectedCategories: this.getSelectedCategoriesForSubmit(),
      });
      this.showConfirm = false;
      this.resetDialogData();
    },

    emitCancel() {
      this.resetDialogData();
      this.$emit('onCancel');
    },

    unitChecked(value) {
      return this.selectedUnits.find(x => x === value);
    },

    categoryChecked(value) {
      return this.selectedCategories.find(x => x.id === value.id);
    },

    allInputChange() {
      const selectedCategories = [];
      const selectedUnits = [];

      const categories = this.workpackageCategories;

      const mappedCategories = categories.map(category => ({
        id: category.id,
        parentId: category.parentId,
      }));
      selectedCategories.push(...mappedCategories);

      const mappedUnits = this.workpackageUnits.map(unit => unit.id);
      selectedUnits.push(...mappedUnits);

      if (this.selectedCategories.length !== categories.length) {
        this.selectedCategories = selectedCategories;
        this.selectedUnits = selectedUnits;
        return;
      }
      this.selectedCategories = [];
      this.selectedUnits = [];
    },

    unitChange(value) {
      const hasUnit = this.selectedUnits.find(x => x === value);
      const categories = this.workpackageCategories;

      if (!hasUnit) {
        const categoriesForUnit = categories.filter(x => x.parentId === value);
        categoriesForUnit.forEach(category => {
          const selectedCategory = this.selectedCategories.find(x => x.id === category.id);
          if (!selectedCategory) {
            this.selectedCategories.push({ id: category.id, parentId: category.parentId });
          }
        });
        this.selectedUnits.push(value);
        return;
      }

      const categoriesForUnit = categories.filter(x => x.parentId === value);
      categoriesForUnit.forEach(category => {
        this.selectedCategories = this.selectedCategories.filter(x => x.id !== category.id);
      });
      this.selectedUnits = this.selectedUnits.filter(x => x !== value);
    },

    categoryChange(value) {
      const hasCategory = this.selectedCategories.find(x => x.id === value.id);

      if (!hasCategory) {
        this.selectedCategories.push(value);
      } else {
        this.selectedCategories = this.selectedCategories.filter(x => x.id !== value.id);
      }
      const selectedCategoriesForUnit = this.selectedCategories.filter(
        x => x.parentId === value.parentId
      );
      const categoriesForUnit = this.workpackageCategories.filter(
        x => x.parentId === value.parentId
      );
      const categoryUnit = this.selectedUnits.filter(x => x === value.parentId);

      if (
        categoriesForUnit.length === selectedCategoriesForUnit.length &&
        categoryUnit.length === 0
      ) {
        this.selectedUnits.push(value.parentId);
      } else if (
        categoriesForUnit.length !== selectedCategoriesForUnit.length &&
        categoryUnit.length !== 0
      ) {
        this.selectedUnits = this.selectedUnits.filter(x => x !== value.parentId);
      }
    },

    getSelectedCategoriesForSubmit() {
      return this.selectedCategories.map(category => category.id);
    },

    async checkIfSelectedFieldsCanBeTransferred() {
      const destinationWorkpackageId = this.destinationWorkpackage._id;
      const sourceWorkpackageId = this.workpackageToTransfer._id;
      const fields = this.selectedFields.map(field => field.id);
      this.dataValidationInProgress = true;
      const result = await this.validateDataBeforeTransferring({
        destinationWorkpackageId,
        sourceWorkpackageId,
        categories: this.getSelectedCategoriesForSubmit(),
        fields,
      });
      this.dataValidationInProgress = false;
      this.validationErrors = this.getValidationErrors(
        this.destinationWorkpackage.description,
        result
      );
    },

    async confirmTransferring() {
      await this.checkIfSelectedFieldsCanBeTransferred();
      if (!isEmpty(this.validationErrors)) {
        return;
      }
      this.showConfirm = true;
    },

    getValidationErrors(destinationWorkpackageName, result) {
      const {
        invalidCategories,
        absentAttributesInArchitectureDrivers,
        absentAttributesInRules,
        absentCompetitorsInRules,
      } = result;
      const invalidCategoriesErrors = isEmpty(invalidCategories)
        ? ''
        : this.$t('transferWorkpackage.errors.invalidCategories').replace(
            '{{categories}}',
            invalidCategories.join(', ')
          );
      const absentAttributesInArchitectureDriversError = isEmpty(
        absentAttributesInArchitectureDrivers
      )
        ? ''
        : this.$t('transferWorkpackage.errors.absentAttributesInArchitectureDrivers').replace(
            '{{attributes}}',
            absentAttributesInArchitectureDrivers.join(', ')
          );
      const absentAttributesInRulesError = isEmpty(absentAttributesInRules)
        ? ''
        : this.$t('transferWorkpackage.errors.absentAttributesInRules').replace(
            '{{rules}}',
            absentAttributesInRules.join(', ')
          );
      const absentCompetitorsInRulesError = isEmpty(absentCompetitorsInRules)
        ? ''
        : this.$t('transferWorkpackage.errors.absentCompetitorsInRules').replace(
            '{{rules}}',
            absentCompetitorsInRules.join(', ')
          );
      return [
        invalidCategoriesErrors,
        absentAttributesInArchitectureDriversError,
        absentAttributesInRulesError,
        absentCompetitorsInRulesError,
      ]
        .filter(err => !!err)
        .map(error => error.replace('{{target_workpackage}}', destinationWorkpackageName));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
$primary-font-size: 1.4rem;

.v-card {
  .body.v-card__text {
    font-size: $primary-font-size;
    margin-bottom: 7rem;
  }
}

.validating-transfer-message,
.validation-errors {
  font-size: $primary-font-size;
}
.validation-errors {
  margin: 0 2rem;
  min-height: 10rem;
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';
.transfer-dialog {
  overflow: hidden;
  .v-input__slot {
    padding: unset !important;
  }

  border-left: 0.5rem solid $pricing-primary;

  &.alert-border {
    border-left: 0.5rem solid $alertsIconColor;
  }

  .confirmation {
    text-align: center;
  }

  .alert-banner {
    margin-left: -0.5rem;
    color: white;

    .v-icon {
      color: white;
    }
  }

  .confirm-btns {
    display: flex;
    justify-content: center;
  }

  .workpackage-dropdown {
    border-bottom: 0.2rem solid $pricing-primary;
    border-radius: unset;

    .text,
    label {
      font-size: 1rem;
      color: $dropdown-placeholder !important;
    }

    .v-select__slot {
      background-color: $pricing-background;
    }
    .v-input__control {
      min-height: 2.4rem !important;
    }

    input {
      background-color: $pricing-background;
      padding: 0 0.3rem;
      color: $dropdown-placeholder !important;
    }

    icon {
      color: $pricing-primary;
    }
  }
}
.main-section {
  padding: 0 2rem;

  .sections {
    margin: 0.6rem 1rem;
    border-right: $pricing-grey solid 0.1rem;
    font-size: 1.4rem;

    &.scrollable {
      max-height: 26rem;
      overflow-x: hidden;
    }

    .heading {
      margin-bottom: 0.2rem;
    }

    .warning-message {
      color: $pricing-red;
      margin-top: 1rem;
    }

    .transfer-item {
      padding: 0.1rem;
      align-items: center;
      input {
        margin-right: 0.6rem;
        &:checked {
          &span::before {
            background: black;
          }
        }
      }
    }

    .from-item {
      padding: 0.1rem;
      margin-right: 0.2rem;

      &.border {
        border: $pricing-grey solid 0.1rem;
        padding: unset;
      }

      .heading {
        padding: 0 0.3rem;
        display: flex;
        justify-content: space-between;

        span {
          display: flex;
          align-items: center;

          input {
            margin: 0 0.6rem;
            padding: 0 0.6rem;
          }
        }
      }

      .options {
        padding: 0 0.3rem;

        &:first-child {
          border-right: $pricing-grey solid 0.05rem;
        }

        span {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding: 0.2rem 0;
          align-items: center;

          &:first-child {
            margin-top: 0.3rem;
          }

          input {
            margin: 0 0.6rem;
            padding: 0 0.6rem;
          }
        }
      }
    }
  }
  .col:last-child {
    border-right: unset;
  }
}
</style>
