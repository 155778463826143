import axios from 'axios';
import to from 'await-to-js';
import { get, includes, isEmpty, startCase } from 'lodash';
import { testUsers } from '@sharedModules/config/dev-users';
import hasRolesCallback from '../utils/hasRoles';
import { datadogRum } from '@datadog/browser-rum';

const getInitialContextState = () => ({
  loginMethod: null, // primary authMethod from auth.js config file
  loginRedirectUrl: null,
  oktaClientId: null,
  versionTag: null,
  env: null,
  subBrand: null,
  datadog: {},
  clientUrl: null,
});

const getInitialUserContextState = () => ({
  profile: null,
  csrfToken: null,
  loading: false,
  currentLoginMethod: null, // authMethod used on session scope, needed for correct logout
  expireAt: null,
});

const store = {
  namespaced: true,

  state: { ...getInitialContextState(), ...getInitialUserContextState },

  getters: {
    hasPermission: state => permission => includes(state.profile.permissions, permission),

    hasRole: state => role =>
      state.profile && state.profile.roles && includes(state.profile.roles, role),

    hasRoles: state => roles => hasRolesCallback(state, roles),

    isUnitManager: (state, getters, rootState) => {
      const { unitManager, unitManagerAdmin, admin } = rootState.clientConfig.userRoles;
      return getters.hasRoles([unitManager, unitManagerAdmin, admin]);
    },

    isPricingSpecialist: (state, getters, rootState) => {
      const { pricingSpecialist, pricingSpecialistAdmin, admin } = rootState.clientConfig.userRoles;
      return getters.hasRoles([pricingSpecialist, pricingSpecialistAdmin, admin]);
    },

    isPricingManager: (state, getters, rootState) => {
      const { pricingManager, pricingManagerAdmin, admin } = rootState.clientConfig.userRoles;
      return getters.hasRoles([pricingManager, pricingManagerAdmin, admin]);
    },

    isCategoryManager: (state, getters, rootState) => {
      const { categoryManager, categoryManagerAdmin, admin } = rootState.clientConfig.userRoles;
      return getters.hasRoles([categoryManager, categoryManagerAdmin, admin]);
    },

    isWholesaleManager: (state, getters, rootState) => {
      const { wholesaleManager, admin } = rootState.clientConfig.userRoles;
      return getters.hasRoles([wholesaleManager, admin]);
    },

    isWholesaleAnalyst: (state, getters, rootState) => {
      const { wholesaleAnalyst, admin } = rootState.clientConfig.userRoles;
      return getters.hasRoles([wholesaleAnalyst, admin]);
    },

    isAdminLoggedIn: (state, getters, rootState) => {
      return getters.hasRoles([rootState.clientConfig.userRoles.admin]);
    },

    isAdmin: (state, getters, rootState) => {
      return getters.hasRoles(rootState.clientConfig.userRoles.adminRoles);
    },

    isOWUser: state => {
      // /ow-auth/login profile uses email prop to store email
      if (get(state, 'profile.email', '').endsWith('oliverwyman.com')) return true;

      // /login profile uses username prop to store email
      const devUserNames = testUsers.map(tu => tu.username);
      if (devUserNames.includes(get(state, 'profile.username', ''))) return true;

      return false;
    },

    canExecuteWorkpackage: (state, getters, rootState) => {
      return getters.hasRoles(rootState.clientConfig.userRoles.executeWorkpackageRoles);
    },

    canEditWorkpackage: (state, getters, rootState) => {
      return getters.hasRoles(rootState.clientConfig.userRoles.editWorkpackageRoles);
    },

    units: state => get(state, 'profile.access.Unit', []).map(({ levelEntryKey }) => levelEntryKey),

    profileNameAndInitials: state => {
      // try to construct fullName and initials from user profile
      // first using properties (samlProfile firstname, surname)
      // next derive from local-part of email
      // finally fall back to default response missingProfile

      const email = get(state, 'profile.email', '');
      const firstname = get(state, 'profile.firstname', '');
      const surname = get(state, 'profile.surname', '');
      if (!isEmpty(firstname) && !isEmpty(surname)) {
        return {
          fullName: `${firstname} ${surname}`,
          initials: startCase(`${get(firstname, 0)}${get(surname, 0)}`),
        };
      }

      if (isEmpty(email))
        return {
          fullName: '',
          initials: 'N/A',
        };

      // case for AD banners missing firstname / surname props on samlProfile
      const stem = email.split('@')[0];
      const onlyUppercase = stem.replace(/[a-z]/g, '');
      // try first and last uppercase letter, if < 2 uppercase letters just use first and last letter
      const initials =
        onlyUppercase.length < 2
          ? startCase(`${get(stem, 0)}${get(stem, stem.length - 1)}`)
          : startCase(`${get(onlyUppercase, 0)}${get(onlyUppercase, onlyUppercase.length - 1)}`);
      return {
        fullName: stem,
        initials,
      };
    },
  },

  mutations: {
    setContext(state, contextData) {
      Object.assign(state, contextData);
    },
    setUserContext(state, profile) {
      state.profile = profile;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoginMethod(state, method) {
      state.loginMethod = method;
    },
    setCurrentLoginMethod(state, method) {
      state.currentLoginMethod = method;
    },
    setExpireAt(state, expireAt) {
      state.expireAt = expireAt;
    },
    setCsrfToken(state, token) {
      state.csrfToken = token;
    },
    resetUserContextState(state) {
      Object.assign(state, { ...state, ...getInitialUserContextState() });
    },
  },

  actions: {
    loginHardcoded({ dispatch, commit }, credentials) {
      return axios.post('/api/login', credentials).then(
        () => {
          commit('setCurrentLoginMethod', 'ow-auth-hardcoded');
          return dispatch('loadUserContext');
        },
        err => {
          console.error(err.response.data.message);
          commit('setCurrentLoginMethod', 'ow-auth-hardcoded');
        }
      );
    },

    loginOkta({ dispatch, commit }, token) {
      return axios.post('/api/ow-auth/login', { token }).then(
        () => {
          commit('setCurrentLoginMethod', 'ow-auth-okta');
          return dispatch('loadUserContext');
        },
        err => {
          console.error(err.response.data.message);
          commit('setCurrentLoginMethod', 'ow-auth-okta');
        }
      );
    },

    setCurrentLoginMethod({ commit }, method) {
      commit('setCurrentLoginMethod', method);
    },

    loadContext({ commit }) {
      return axios.get('/api/context').then(res => commit('setContext', res.data));
    },

    async loadUserContext({ state, dispatch, commit }) {
      const [err, res] = await to(axios.get('/api/user-context'));
      if (err) throw new Error(err.message);

      const { data } = res;

      if (!data) return;
      datadogRum.setUser({
        id: data.profile._id,
        name: data.profile.username,
        hostname: state.clientUrl,
      });
      datadogRum.startSessionReplayRecording();
      // save tokenLifeTime in milliseconds if valid login exists
      const tokenLifetime = (data.exp - data.iat) * 1000;
      const expirationDate = Date.now() + tokenLifetime;
      commit('setUserContext', data.profile);
      commit('setCsrfToken', data.csrfToken);
      commit('setExpireAt', expirationDate);

      // this is where we know the user has successfully authenticated so we can load state
      await dispatch('initialiseState', null, { root: true });
    },

    refreshUserContext({ dispatch }) {
      return axios.post('/api/token/refresh').then(() => {
        return Promise.all([
          dispatch('loadContext'),
          dispatch('notifications/openNotificationStream', {}, { root: true }),
        ]);
      });
    },

    logout({ state, commit }) {
      let logoutUrl = '/api/logout';
      if (state.currentLoginMethod === 'ow-auth-okta') logoutUrl = '/api/ow-auth/logout';
      if (state.currentLoginMethod === 'ah-auth-saml') logoutUrl = '/api/ah-auth/logout';

      return axios.get(logoutUrl).then(res => {
        commit('setUserContext', null);
        commit('setCsrfToken', null);

        if (state.currentLoginMethod === 'ah-auth-saml') {
          // The SAML strategy will redirect to SAML logout page, this one
          // to our logout/callback and finally back to our client app
          window.location.href = res.data.redirectUrl;
          return Promise.reject();
        }
        return Promise.resolve(
          state.currentLoginMethod === 'ow-auth-okta' ? '/ow-auth/login' : '/login'
        );
      });
    },

    resetState({ commit }) {
      commit('resetUserContextState');
    },
  },
};

export default store;
