import loadRoutes from './loadRoutes';
import registerComponents from './registerComponents';
import registerDirectivesAndFilters from './registerDirectivesAndFilters';

function install(Vue) {
  if (install.installed) return;
  install.installed = true;

  Vue.loadRoutes = function(folder) {
    return loadRoutes(Vue, folder);
  };

  Vue.registerComponents = function(folder) {
    return registerComponents(Vue, folder);
  };

  Vue.registerDirectivesAndFilters = function(req) {
    return registerDirectivesAndFilters(Vue, req);
  };
}

// Export individual components and utilities
// so they can be imported like import {datepicker} from 'vue-plugins';
export { registerComponents, registerDirectivesAndFilters, loadRoutes };

// Use the install function as default export to include everything
export default install;
