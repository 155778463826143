'use strict';

const workpackageTypes = require('./workpackage-types');
const ruleStatus = require('./rule-status');
const overwriteField = require('./overwrite-field');

/**
 * An enum that defines the sequence for applying overwrites. Priority is not a mandatory field.
 * Priority should be set only if we need to apply an overwrite before others overwrites.
 * Most overwrites do not need priority.
 */
const overwritePriority = {
  attributes: 1,
  competition: 2,
};

/**
 * {
 *    collections: {
 *      name: string; // updated mongodb collection name
 *      filter: string[]; // list of fields that are used as a filter for an update object, filter values are getting from a document from a source workpackage
 *      sourceStaticFilter: object, // extra static mongodb filter required to filter data of the source workpackage, merged with default filter (the source workpackage id)
 *    }[];
 *    fields: {source: string; destination: string; removeIfNull: boolean, projectionOnly: boolean}[]; // overridden fields
 *    // projectionOnly indicates if a field is only required for a projection and should not be removed from updates.
 *    // if projectionOnly=false and there is no the same field where projectionOnly=true, a field will be automatically added to fieldsToRemove
 *    fieldsToRemove: string[]; // maybe we are transferring a partial object (mandatoryEngineInputs) and we need to remove this to prevent overriding other fields
 *    translationKey: string; // translation key is displayed in the UI
 *    transferDisabled: boolean; // indicates if transfer is disabled (UI)
 *    allowedSources: string[]; // list of source types (workpackageTypes) for which transferring is allowed
 *    id: string; //unique id,
 *    runEngine: boolean; // indicates if we should trigger recalculate_all for the destination workpackage after the transfer
 *  }[]
 */
module.exports = [
  {
    collections: [
      {
        name: 'price-overrides',
        filter: ['workpackageId', 'productKey', 'toolStoreGroupKey', 'scenarioKey'],
        fields: [{ source: 'overridePrice', destination: 'overridePrice' }],
        sourceStaticFilter: {
          'overridePrice.isSmart': true,
        },
        upsert: true,
      },
    ],

    // On the above optimizedPrice field, this can be removed if
    // we allow the user too run the engine after the transfer
    translationKey: 'transferWorkpackage.fields.overridePrice',
    id: overwriteField.priceOverrides,
    transferDisabled: false,
    runEngine: true,
    allowedSources: [workpackageTypes.master, workpackageTypes.workpackage],
  },
  {
    collections: [
      {
        name: 'workpackage-product',
        filter: ['article.productKey', 'toolStoreGroupKey', 'workpackageId'],
        sourceStaticFilter: {},
        fieldsToRemove: ['mandatoryEngineInputs'],
      },
    ],
    fields: [
      {
        source: 'mandatoryEngineInputs.targetMargin',
        destination: 'mandatoryEngineInputs.targetMargin',
      },
      {
        source: 'mandatoryEngineInputs.nonPromoNetCost',
        destination: 'mandatoryEngineInputs.nonPromoNetCost',
      },
      {
        source: 'mandatoryEngineInputs.intentionCost',
        destination: 'mandatoryEngineInputs.intentionCost',
      },
      {
        source: 'mandatoryEngineInputs.promoParticipationSales',
        destination: 'mandatoryEngineInputs.promoParticipationSales',
      },
      {
        source: 'mandatoryEngineInputs.promoDiscount',
        destination: 'mandatoryEngineInputs.promoDiscount',
      },
      {
        source: 'mandatoryEngineInputs.promoFunding',
        destination: 'mandatoryEngineInputs.promoFunding',
      },
      {
        source: 'intentionCost',
        destination: 'intentionCost',
      },
      // "attributes" is required to compute intentionPrice/intentionCost
      {
        source: 'attributes',
        destination: 'attributes',
        projectionOnly: true,
      },
      // keep costHistoryWeek in sync with mandatoryEngineInputs.intentionCost
      {
        source: 'costHistoryWeek',
        destination: 'costHistoryWeek',
      },
    ],
    translationKey: 'transferWorkpackage.fields.mandatoryEngineInputs',
    id: overwriteField.engineInputs,
    transferDisabled: false,
    runEngine: true,
    allowedSources: [workpackageTypes.master, workpackageTypes.workpackage],
  },
  {
    collections: [
      {
        name: 'workpackage-product',
        filter: ['article.productKey', 'toolStoreGroupKey', 'workpackageId'],
        sourceStaticFilter: {},
        fieldsToRemove: ['mandatoryEngineInputs'],
      },
    ],
    fields: [
      {
        source: 'intentionCost',
        destination: 'intentionCost',
      },
      {
        source: 'attributes',
        destination: 'attributes',
      },
      {
        source: 'costHistoryWeek',
        destination: 'costHistoryWeek',
      },
      {
        source: 'priceHistoryWeek',
        destination: 'priceHistoryWeek',
      },
      {
        source: 'article',
        destination: 'article',
        projectionOnly: true,
      },
      {
        source: 'intentionMargin',
        destination: 'intentionMargin',
      },
      {
        source: 'intentionPrice',
        destination: 'intentionPrice',
      },
      {
        source: 'livePrice',
        destination: 'livePrice',
      },
      {
        source: 'liveCost',
        destination: 'liveCost',
      },
      {
        source: 'scenarioCost',
        destination: 'scenarioCost',
      },
      {
        source: 'promotionTimeFrames',
        destination: 'promotionTimeFrames',
      },
      {
        source: 'yearlySales',
        destination: 'yearlySales',
      },
      {
        source: 'yearlyVolume',
        destination: 'yearlyVolume',
      },
      {
        source: 'volumePreviousYear',
        destination: 'volumePreviousYear',
      },
      {
        source: 'volumeYTDPrevious',
        destination: 'volumeYTDPrevious',
      },
      {
        source: 'volumeYTD',
        destination: 'volumeYTD',
      },
      {
        source: 'volumeYearToGo',
        destination: 'volumeYearToGo',
        removeIfNull: true,
      },
      {
        source: 'toolStoreGroupDescription',
        destination: 'toolStoreGroupDescription',
      },
      {
        source: 'mandatoryEngineInputs.intentionCost',
        destination: 'mandatoryEngineInputs.intentionCost',
      },
      {
        source: 'wholesale',
        destination: 'wholesale',
      },
    ],
    translationKey: 'transferWorkpackage.fields.productInformation',
    id: overwriteField.productInformation,
    transferDisabled: false,
    runEngine: true,
    allowedSources: [workpackageTypes.master],
  },
  {
    collections: [
      {
        name: 'settings-rules',
        filter: ['workpackageId', 'scenarioKey'],
        sourceStaticFilter: { status: ruleStatus.active },
        insert: true,
      },
    ],
    fields: ['ALL_FIELDS'],
    translationKey: 'transferWorkpackage.fields.penaltyRules',
    id: overwriteField.penaltyRules,
    transferDisabled: false,
    allowedSources: [workpackageTypes.master, workpackageTypes.workpackage],
  },
  {
    collections: [
      {
        name: 'competitor-metadata',
        filter: ['workpackageId', 'competitorKey', 'competitorType'],
        fields: ['ALL_FIELDS'],
        sourceStaticFilter: { competitorType: { $ne: 'storeGroup' } },
        upsert: true,
      },
      {
        name: 'workpackage-product',
        filter: ['article.productKey', 'toolStoreGroupKey', 'workpackageId'],
        fields: [
          { source: 'competitor', destination: 'competitor', projectionOnly: true },
          { source: 'competitorSpecs', destination: 'competitorSpecs' },
        ],
        sourceStaticFilter: {},
      },
    ],
    translationKey: 'transferWorkpackage.fields.competition',
    id: overwriteField.competition,
    transferDisabled: false,
    runEngine: true,
    allowedSources: [workpackageTypes.master, workpackageTypes.workpackage],
    priority: overwritePriority.competition,
  },
  {
    collections: [
      {
        name: 'attributes',
        filter: [],
        sourceStaticFilter: {},
      },
      {
        name: 'attribute-metadata',
        filter: ['workpackageId', 'attributeKey'],
        sourceStaticFilter: {},
        upsert: true,
      },
    ],
    fields: ['ALL_FIELDS'],
    translationKey: 'transferWorkpackage.fields.attributes',
    id: overwriteField.attributes,
    transferDisabled: false,
    allowedSources: [workpackageTypes.master, workpackageTypes.workpackage],
    priority: overwritePriority.attributes,
  },
  {
    collections: [
      {
        name: 'architecture-drivers',
        filter: ['workpackageId', 'attributeKey', 'architectureGroupId', 'scenarioKey'],
        sourceStaticFilter: {},
        upsert: true,
      },
      {
        name: 'product-impact',
        filter: ['workpackageId', 'productKey', 'toolStoreGroupKey', 'scenarioKey'],
        sourceStaticFilter: {},
        upsert: true,
      },
      {
        name: 'line-pricing-groups',
        filter: ['workpackageId', 'linePricingGroupKey', 'architectureGroupId', 'scenarioKey'],
        sourceStaticFilter: {},
        upsert: true,
      },
      {
        name: 'line-priced-products',
        filter: ['workpackageId', 'productKey', 'toolStoreGroupKey', 'scenarioKey'],
        sourceStaticFilter: {},
        upsert: true,
      },
      {
        name: 'scenario-metadata',
        filter: ['workpackageId', 'hierarchyId', 'scenarioKey'],
        fields: [
          {
            source: 'subGroupSplittingAttributes',
            destination: 'subGroupSplittingAttributes',
            removeIfNull: true,
          },
        ],
        sourceStaticFilter: {},
      },
    ],
    fields: ['ALL_FIELDS'],
    translationKey: 'transferWorkpackage.fields.architectureSetup',
    id: overwriteField.architectureSetup,
    transferDisabled: false,
    allowedSources: [workpackageTypes.master, workpackageTypes.workpackage],
  },
];
