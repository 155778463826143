<template>
  <input type="checkbox" :checked="value" @click="checkedHandler()" />
</template>

<script>
import Vue from 'vue';
import { isNil, get } from 'lodash';

export default Vue.extend({
  computed: {
    negateValue() {
      return this.params.negate || false;
    },

    value() {
      const initialValue = isNil(this.params.value) ? this.negateValue : this.params.value;
      return this.negateValue ? !initialValue : initialValue;
    },
  },
  methods: {
    checkedHandler() {
      const checkValue = !this.value;
      const value = this.negateValue ? !checkValue : checkValue;
      // AG Grid behavior: If RowNode data is nested but not fully defined for this.params.colDef.field,
      // it won't update. Ensure all levels of the object are fully defined up to the second-to-last level.
      if (isNil(get(this.params.node, this.params.colDef.field))) {
        const fieldPath = this.params.colDef.field;
        const fieldPieces = fieldPath.split('.');
        let currentObject = this.params.node.data;
        while (fieldPieces.length > 1 && currentObject) {
          const fieldPiece = fieldPieces.shift();
          if (!currentObject[fieldPiece]) {
            currentObject[fieldPiece] = {};
          }
          currentObject = currentObject[fieldPiece];
        }
      }
      this.params.node.setDataValue(this.params.column.colId, value);
      if (this.params.setter) this.params.setter(this.params, value);
    },
  },
});
</script>
