/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import moment from 'moment';
import { get } from 'lodash';

import { paramsSerializer } from './utils/legacy-params-serializer';

let currentRefreshTokenRequest = null;

const interceptors = {
  csrfToken: (context, request) => {
    request.headers['x-csrf-token'] = context.csrfToken;
    return request;
  },

  scenarioDetails: (store, router, request) => {
    request.headers['x-scenario-id'] = get(store.state, 'filters.scenario._id', null);
    request.headers['x-workpackage-id'] = get(
      store.state,
      'workpackages.selectedWorkpackage._id',
      null
    );
    request.headers['x-application-page'] = get(router, 'history.current.name', null);
    return request;
  },

  timezone: (store, request) => {
    const logging = get(store.state, 'context.clientConfig.dateFormats.logging', null);
    // collect minutes of difference between current time and UTC
    // different is UTC - now, so we flip to sum based on UTC later
    request.headers['x-timezone-offset'] = 0 - new Date().getTimezoneOffset();
    request.headers['x-usertime'] = moment().format(logging);
    return request;
  },

  responseError: (store, router, error) => {
    // Was this cancelled by our code?
    if (axios.isCancel(error)) {
      // If so, pass through the error
      return Promise.reject(error);
    }

    const status = error.response ? error.response.status : ''; // some errors do not have response defined

    if (status === 401) {
      // ensure it is not because of a hardcoded login attempt with invalid credentials
      if (error.config.url.endsWith('/login')) return Promise.reject(error);

      // Try first refreshing the token
      const shouldAttemptRefresh = error.response.data && error.response.data.attemptTokenRefresh;
      if (
        shouldAttemptRefresh &&
        !error.config.url.endsWith('/api/token/refresh') &&
        !error.config._triedRefreshToken &&
        !error.config._refreshFailed
      ) {
        // Do not attempt multiple requests to refresh in parallel, or they would fail since the last ones will attempt to refresh outdated tokens
        error.config._triedRefreshToken = true;
        if (!currentRefreshTokenRequest) {
          currentRefreshTokenRequest = store
            .dispatch('context/refreshUserContext')
            .then(() => {
              currentRefreshTokenRequest = null;
            })
            .catch(() => {
              currentRefreshTokenRequest = null;
              // if the token refresh fails, let the error handler decide what to do with the original request, but mark the request as having failed to refresh
              error.config._refreshFailed = true;
              return interceptors.responseError(store, router, error);
            });
        }

        // Once the token has been successfully refreshed, retry the original request, otherwise just pass back the result of the error handler
        // Any errors with this request would go through the interceptor for the new request, as part of the axios.request() call
        return currentRefreshTokenRequest.then(res => {
          if (!error.config._refreshFailed) {
            const request = error.config;
            // Undo axios processing to prevent 400
            try {
              const object = JSON.parse(request.data);
              if (object && typeof object === 'object') {
                request.data = object;
              }
            } catch (err) {
              console.log(
                `Unexpected error parsing JSON during request retry: ${err}. This should be investigated.`
              );
            }
            return axios.request(request);
          }
          return Promise.resolve(res);
        });
      }

      // Were we loading the /user-context as part of application startup?
      // If we couldnt load it even after trying refreshing the auth tokens, return empty context and let the bootstrap process to finish
      // The router navigation guard will navigate to the login page (since there will be no profile in the context)
      if (error.config.url.endsWith('/user-context')) {
        return Promise.resolve({ data: null });
      }

      // User needs to authenticate, navigate to the login page
      const pathObject = router.currentRoute.path.includes('/login')
        ? { path: router.currentRoute.path, query: router.currentRoute.query }
        : { path: '/login', query: { returnPath: router.currentRoute.path } };

      router.replace(pathObject);

      return Promise.reject(error);
    }

    // 403 for token refresh means wrong csrf token in FE request
    // in this case we need to refresh it by loading context
    if (status === 403 && error.config.url.endsWith('/api/token/refresh')) {
      console.log('Refreshing CSRF token');
      store.dispatch('context/loadContext').then(() => router.replace({ path: '/login' }));
    }

    // 403: user is logged in and has no permission to access a resource
    // 477: modsecurity rules fail
    if ([403, 477].includes(status)) {
      router.replace({ path: `/error/${status}` });
    }

    return Promise.reject(error);
  },
};

const install = (Vue, options) => {
  axios.interceptors.request.use(interceptors.csrfToken.bind(null, options.store.state.context));
  axios.interceptors.response.use(
    null,
    interceptors.responseError.bind(null, options.store, options.router)
  );
  axios.interceptors.request.use(
    interceptors.scenarioDetails.bind(null, options.store, options.router)
  );
  axios.interceptors.request.use(interceptors.timezone.bind(null, options.store));

  axios.defaults.withCredentials = true; // make sure we store/send cookies in CORS requests between frontend and backend

  axios.defaults.paramsSerializer = paramsSerializer; // legacy params serializer from axios 0.2x.

  Vue.prototype.$http = axios;
};

export default {
  interceptors, // Technically not needed, but will help with testing
  install,
};
