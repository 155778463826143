import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasSelectedWorkpackage,
          expression: "hasSelectedWorkpackage",
        },
      ],
    },
    [
      _c("attribute-filter-panel", {
        key:
          "attribute-filter-panel-" + _vm.isFilteringByArchitectureGroupEnabled,
        attrs: {
          "filter-rules": _vm.retailAttributesFilter,
          "enable-hierarchy-filters": "",
          "title-localisation": "attributes.filters.filterByAttributes",
          "filter-count-localisation": "attributes.filters.numApplied",
        },
        on: { attributeFilterChange: _vm.setGridViewFilter },
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("basic-spinner", { staticClass: "spinner-wrapper" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-center flex-column" },
        [
          [
            _c(
              VFadeTransition,
              [
                _vm.noProductsMatchingFilters && _vm.recalculatingAll
                  ? _c(VRow, { key: "recalculating" }, [
                      _c("span", { staticClass: "notification-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("gridView.noProductsWhileRecalculatingAll")
                          )
                        ),
                      ]),
                    ])
                  : _vm.noProductsMatchingFilters
                  ? _c(VRow, { key: "empty-results" }, [
                      _c("span", { staticClass: "notification-text mb-5" }, [
                        _vm._v(_vm._s(_vm.$t("gridView.noResultsToDisplay"))),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "hierarchy__container" }, [
        _c(
          "table",
          { staticClass: "hierarchy__table" },
          _vm._l(_vm.tableRows, function (item) {
            return _c(
              item.component,
              _vm._b(
                {
                  key: item.metaId,
                  tag: "component",
                  on: {
                    releaseCategory: _vm.releaseCategory,
                    releasePricingGroup: _vm.releasePG,
                    pricingSpecialistPricingGroupApproval:
                      _vm.pricingSpecialistPricingGroupApproval,
                    pricingSpecialistCategoryApproval:
                      _vm.pricingSpecialistCategoryApproval,
                    categoryManagerPricingGroupApproval:
                      _vm.categoryManagerPricingGroupApproval,
                    categoryManagerCategoryApproval:
                      _vm.categoryManagerCategoryApproval,
                    unitApproval: _vm.approveUnit,
                    toggleFilteringByArchitectureGroup:
                      _vm.onToggleFilteringByArchitectureGroup,
                  },
                },
                "component",
                item.data,
                false
              )
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isReleaseConfirmDialogueOpen,
          "ok-btn-text": _vm.$t("actions.ok"),
        },
        on: {
          onOk: function ($event) {
            return _vm.releasePricingGroup(true)
          },
          onCancel: function ($event) {
            return _vm.releasePricingGroup(false)
          },
        },
        scopedSlots: _vm._u(
          [
            _vm.categoryToRelease
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$t("dialogs.releaseDialogs.headerCategory")
                          ) +
                          "\n    "
                      ),
                    ]
                  },
                  proxy: true,
                }
              : {
                  key: "header",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$t("dialogs.releaseDialogs.headerPricingGroup")
                          ) +
                          "\n    "
                      ),
                    ]
                  },
                  proxy: true,
                },
            _vm.categoryToRelease
              ? {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$t("dialogs.releaseDialogs.bodyCategory")
                          ) +
                          "\n      "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.categoryToRelease
                              ? _vm.categoryToRelease.name
                              : ""
                          )
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$t("dialogs.releaseDialogs.bodyPricingGroup")
                          ) +
                          "\n      "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.pricingGroupToRelease
                              ? _vm.pricingGroupToRelease.name
                              : ""
                          )
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c("grid-view-add-scenario-dialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }