import { VChip } from 'vuetify/lib/components/VChip';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "contents" } },
    [
      _c("td", { staticClass: "hierarchy-level-item__data-cell text-right" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.formatNumber({
                number: _vm.salesMAT / 1000,
                format: _vm.numberFormats.integer,
                zeroAsDash: true,
              })
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "hierarchy-level-item__data-cell text-right" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.formatNumber({
                number: _vm.promoShare,
                format: _vm.numberFormats.percent,
                zeroAsDash: true,
              })
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "hierarchy-level-item__data-cell text-right highlight",
          class: _vm.formatNumberColourClass(_vm.impacts.sales),
        },
        [
          _c(
            "tooltip",
            {
              attrs: {
                value: {
                  [_vm.$t(
                    `gridView.${
                      _vm.showRegularImpact
                        ? "regularImpactInYear"
                        : "totalImpactInYear"
                    }`
                  )]: _vm.formatNumber({
                    number: _vm.impacts.salesInYear,
                    format: _vm.numberFormats.integer,
                    zeroAsDash: true,
                  }),
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.formatNumber({
                      number: _vm.impacts.sales,
                      format: _vm.numberFormats.integer,
                      zeroAsDash: true,
                    })
                  ) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass:
            "hierarchy-level-item__data-cell text-right hierarchy-level-item__group-end border-right highlight",
          class: _vm.formatNumberColourClass(_vm.impacts.cost, true),
        },
        [
          _c(
            "tooltip",
            {
              attrs: {
                value: {
                  [_vm.$t("gridView.costImpactInYear")]: _vm.formatNumber({
                    number: -_vm.impacts.costInYear,
                    format: _vm.numberFormats.integer,
                    zeroAsDash: true,
                  }),
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.formatNumber({
                      number: -_vm.impacts.cost,
                      format: _vm.numberFormats.integer,
                      zeroAsDash: true,
                    })
                  ) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("td", { staticClass: "hierarchy-level-item__data-cell text-right" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.formatNumber({
                number: _vm.marginProposed,
                format: _vm.numberFormats.percent,
                format: _vm.extraDecimalPlacesForMarginDelta
                  ? _vm.numberFormats.percentWithExtraDecimalPlaces
                  : _vm.numberFormats.percent,
                zeroAsDash: true,
              })
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass:
            "hierarchy-level-item__data-cell hierarchy-level-item__group-end text-right highlight border-right",
          class: _vm.formatNumberColourClass(_vm.marginDelta),
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.formatNumber({
                  number: _vm.marginDelta,
                  format: _vm.extraDecimalPlacesForMarginDelta
                    ? _vm.numberFormats.percentWithExtraDecimalPlaces
                    : _vm.numberFormats.percent,
                  zeroAsDash: true,
                })
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.readFromDatabricks
        ? [
            _vm._l(_vm.selectedCompetitors, function ({ competitorKey }) {
              return [
                _vm.isPricingGroupLevel
                  ? _c(
                      "td",
                      {
                        key: `pg-target-distance-${competitorKey}`,
                        staticClass:
                          "hierarchy-level-item__data-cell text-right",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.formatNumber({
                                number:
                                  _vm.competitorTargetDistance[competitorKey]
                                    .targetDistance,
                                format: _vm.numberFormats.priceFormat,
                                zeroAsDash: true,
                              })
                            ) +
                            "\n      "
                        ),
                      ]
                    )
                  : _c(
                      "td",
                      {
                        key: `non-pg-target-distance-${competitorKey}`,
                        staticClass:
                          "hierarchy-level-item__data-cell text-right",
                      },
                      [
                        _c(VTextField, {
                          attrs: {
                            disabled: !_vm.canWriteTargetDistance,
                            value: _vm.formatNumber({
                              number:
                                _vm.competitorTargetDistance[competitorKey]
                                  .targetDistance,
                              format: _vm.numberFormats.priceFormat,
                            }),
                            type: "text",
                          },
                          on: {
                            keypress: function ($event) {
                              return _vm.ensureDecimal($event, true)
                            },
                            focus: function ($event) {
                              _vm.previousFieldValue = $event.target.value
                            },
                            change: function ($event) {
                              return _vm.competitorTargetDistanceChange(
                                $event.target.value,
                                competitorKey
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    key: `live-distance-${competitorKey}`,
                    staticClass: "hierarchy-level-item__data-cell text-right",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.formatNumber({
                            number:
                              _vm.selectedWeightValues[competitorKey]
                                .competitorLiveDistance,
                            format: _vm.numberFormats.percent,
                            zeroAsDash: true,
                          })
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    key: `proposed-distance-${competitorKey}`,
                    staticClass: "hierarchy-level-item__data-cell text-right",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.formatNumber({
                            number:
                              _vm.selectedWeightValues[competitorKey]
                                .competitorProposedDistance,
                            format: _vm.numberFormats.percent,
                            zeroAsDash: true,
                          })
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    key: `delta-vs-prev-${competitorKey}`,
                    staticClass:
                      "hierarchy-level-item__data-cell hierarchy-level-item__group-end text-right highlight border-right",
                    class: _vm.formatNumberColourClass(
                      _vm.selectedWeightValues[competitorKey]
                        .competitorDeltaVsPrev
                    ),
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.formatNumber({
                            number:
                              _vm.selectedWeightValues[competitorKey]
                                .competitorDeltaVsPrev,
                            format: _vm.numberFormats.percent,
                            zeroAsDash: true,
                          })
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            }),
          ]
        : [
            _vm.isPricingGroupLevel
              ? _c(
                  "td",
                  { staticClass: "hierarchy-level-item__data-cell text-right" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.formatNumber({
                            number: _vm.competitor1TargetDistance,
                            format: _vm.numberFormats.priceFormat,
                            zeroAsDash: true,
                          })
                        ) +
                        "\n    "
                    ),
                  ]
                )
              : _c(
                  "td",
                  { staticClass: "hierarchy-level-item__data-cell text-right" },
                  [
                    _c(VTextField, {
                      attrs: {
                        disabled: !_vm.canWriteTargetDistance,
                        value: _vm.formatNumber({
                          number: _vm.competitor1TargetDistance,
                          format: _vm.numberFormats.priceFormat,
                        }),
                        type: "text",
                      },
                      on: {
                        keypress: function ($event) {
                          return _vm.ensureDecimal($event, true)
                        },
                        focus: function ($event) {
                          _vm.previousFieldValue = $event.target.value
                        },
                        change: _vm.competitor1TargetDistanceChange,
                      },
                    }),
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "hierarchy-level-item__data-cell text-right" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatNumber({
                        number:
                          _vm.selectedWeightValues.competitor1LiveDistance,
                        format: _vm.numberFormats.percent,
                        zeroAsDash: true,
                      })
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "hierarchy-level-item__data-cell text-right" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatNumber({
                        number:
                          _vm.selectedWeightValues.competitor1ProposedDistance,
                        format: _vm.numberFormats.percent,
                        zeroAsDash: true,
                      })
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass:
                  "hierarchy-level-item__data-cell hierarchy-level-item__group-end text-right highlight border-right",
                class: _vm.formatNumberColourClass(
                  _vm.selectedWeightValues.competitor1DeltaVsPrev
                ),
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatNumber({
                        number: _vm.selectedWeightValues.competitor1DeltaVsPrev,
                        format: _vm.numberFormats.percent,
                        zeroAsDash: true,
                      })
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isPricingGroupLevel
              ? _c(
                  "td",
                  { staticClass: "hierarchy-level-item__data-cell text-right" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.formatNumber({
                            number: _vm.competitor2TargetDistance,
                            format: _vm.numberFormats.priceFormat,
                            zeroAsDash: true,
                          })
                        ) +
                        "\n    "
                    ),
                  ]
                )
              : _c(
                  "td",
                  { staticClass: "hierarchy-level-item__data-cell text-right" },
                  [
                    _c(VTextField, {
                      attrs: {
                        disabled: !_vm.canWriteTargetDistance,
                        value: _vm.formatNumber({
                          number: _vm.competitor2TargetDistance,
                          format: _vm.numberFormats.priceFormat,
                        }),
                        type: "text",
                      },
                      on: {
                        keypress: function ($event) {
                          return _vm.ensureDecimal($event, true)
                        },
                        focus: function ($event) {
                          _vm.previousFieldValue = $event.target.value
                        },
                        change: _vm.competitor2TargetDistanceChange,
                      },
                    }),
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "hierarchy-level-item__data-cell text-right" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatNumber({
                        number:
                          _vm.selectedWeightValues.competitor2LiveDistance,
                        format: _vm.numberFormats.percent,
                        zeroAsDash: true,
                      })
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "hierarchy-level-item__data-cell text-right" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatNumber({
                        number:
                          _vm.selectedWeightValues.competitor2ProposedDistance,
                        format: _vm.numberFormats.percent,
                        zeroAsDash: true,
                      })
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass:
                  "hierarchy-level-item__data-cell hierarchy-level-item__group-end text-right highlight border-right",
                class: _vm.formatNumberColourClass(
                  _vm.selectedWeightValues.competitor2DeltaVsPrev / 1000
                ),
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatNumber({
                        number: _vm.selectedWeightValues.competitor2DeltaVsPrev,
                        format: _vm.numberFormats.percent,
                        zeroAsDash: true,
                      })
                    ) +
                    "\n    "
                ),
              ]
            ),
          ],
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass:
            "hierarchy-level-item__data-cell hierarchy-level-item__group-end border-right",
        },
        [
          _c(
            "tension-chart",
            _vm._b({}, "tension-chart", _vm.tensionChartProps, false)
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "hierarchy-level-item__data-cell text-right" },
        [
          _c(VChip, { staticClass: "remove-margin", attrs: { small: "" } }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.formatNumber({
                    number: _vm.numPriceChanges || 0,
                    format: _vm.numberFormats.integer,
                  })
                ) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("td", { staticClass: "alert-cell" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }