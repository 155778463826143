import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "expand-btn",
      class: _vm.expandBtnClassObject,
      on: { click: _vm.toggleExpand },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        VIcon,
        { staticClass: "expand-btn__icon", attrs: { disabled: _vm.disabled } },
        [_vm._v(_vm._s(_vm.name))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }