import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        VBtn,
        {
          staticClass: "pa-0",
          attrs: {
            color: "primary",
            small: "",
            depressed: "",
            loading: _vm.busyImportingEngineInput,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.initiateModal()
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("engineInputs.upload.uploadButton")) +
              "\n    "
          ),
          _c(VIcon, { attrs: { small: "" } }, [_vm._v("$import")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VDialog,
        {
          attrs: { width: "500" },
          model: {
            value: _vm.uploadDialog,
            callback: function ($$v) {
              _vm.uploadDialog = $$v
            },
            expression: "uploadDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("engineInputs.upload.dialogHeading")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isInitial
                ? _c("file-upload", {
                    attrs: {
                      "upload-field-name": _vm.uploadFieldName,
                      "is-saving": _vm.isSaving,
                      source: "inputs",
                    },
                    on: { save: _vm.save },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isValidated
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c("div", { staticClass: "layout wrap" }, [
                            _c("div", { staticClass: "flex xs12" }, [
                              _c("div", { staticClass: "dialog-copy" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "engineInputs.upload.inputsReceived"
                                      )
                                    ) +
                                    ":\n                  "
                                ),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.counts.articlesReceived)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("attributes.upload.confirmUpload")
                                    ) +
                                    "\n                  "
                                ),
                                _vm.templateFutureCostDisabled
                                  ? _c(
                                      "div",
                                      { staticClass: "future-cost-warning" },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            staticClass: "warning-icon",
                                            attrs: {
                                              size: "1.5rem",
                                              color: _vm.alertsIconColor,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      warning\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "engineInputs.upload.futureCostsWillNotBeUpdated"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              attrs: { color: "primary", outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeModal()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "primary",
                              attrs: {
                                disabled: _vm.isUploading,
                                loading: _vm.busyImportingEngineInput,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirm()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("actions.upload")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isUploading
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c("div", { staticClass: "layout wrap" }, [
                            _c("div", { staticClass: "flex xs12" }, [
                              _c("div", { staticClass: "v-input" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("attributes.upload.uploadingState")
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFailed &&
              !(
                _vm.duplicateKeysError ||
                _vm.incorrectTSGsError ||
                _vm.productKeysWithInvalidPromoDiscount ||
                _vm.productKeysWithEffectiveGoLiveDateErrors ||
                _vm.productKeysWithExpiryGoLiveDateErrors ||
                _vm.productKeysWithExpiryEffectiveDateErrors
              )
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c("div", { staticClass: "layout wrap" }, [
                            _vm.uploadError.tooManyRows
                              ? _c("div", { staticClass: "flex xs12" }, [
                                  _c("div", { staticClass: "v-input" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "engineInputs.upload.tooManyRows",
                                            { maxRows: _vm.maxRows }
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ])
                              : _c("div", { staticClass: "flex xs12" }, [
                                  _c("div", { staticClass: "v-input" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "attributes.upload.genericError"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "primary",
                              on: {
                                click: function ($event) {
                                  return _vm.closeModal()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.close")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("feature-toggle", { attrs: { toggle: _vm.useZones } }, [
                _vm.duplicateKeysError || _vm.incorrectTSGsError
                  ? _c(
                      "div",
                      [
                        _c(VCardText, [
                          _c("div", { staticClass: "container grid-list-md" }, [
                            _c(
                              "div",
                              { staticClass: "layout wrap" },
                              [
                                _vm.duplicateKeysError
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "engineInputs.upload.conflictedToolStoreGroupKeys"
                                        ),
                                        details:
                                          _vm.uploadError.duplicateProductKeys,
                                        "unique-key-prefix":
                                          "conflictToolStoreGroupKeys",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.incorrectTSGsError
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "engineInputs.upload.incorrectToolStoreGroups"
                                        ),
                                        details:
                                          _vm.uploadError
                                            .productKeysWithIncorrectTSGs,
                                        "unique-key-prefix":
                                          "incorrectToolStoreGroups",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(VDivider),
                        _vm._v(" "),
                        _c(
                          VCardActions,
                          [
                            _c(VSpacer),
                            _vm._v(" "),
                            _c(
                              VBtn,
                              {
                                staticClass: "primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.closeModal()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("actions.close")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.productKeysWithEffectiveGoLiveDateErrors ||
              _vm.productKeysWithExpiryGoLiveDateErrors ||
              _vm.productKeysWithExpiryEffectiveDateErrors ||
              _vm.productKeysWithInvalidPromoDiscount
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c(
                            "div",
                            { staticClass: "layout wrap" },
                            [
                              _vm.productKeysWithEffectiveGoLiveDateErrors
                                ? _c("upload-error-card", {
                                    attrs: {
                                      "error-message": _vm.$t(
                                        "engineInputs.upload.effectiveGoLiveDateErrors"
                                      ),
                                      details:
                                        _vm.uploadError
                                          .productKeysWithEffectiveGoLiveDateErrors,
                                      "unique-key-prefix":
                                        "effectiveGoLiveDateErrors",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.productKeysWithExpiryGoLiveDateErrors
                                ? _c("upload-error-card", {
                                    attrs: {
                                      "error-message": _vm.$t(
                                        "engineInputs.upload.expiryGoLiveDateErrors"
                                      ),
                                      details:
                                        _vm.uploadError
                                          .productKeysWithExpiryGoLiveDateErrors,
                                      "unique-key-prefix":
                                        "expiryGoLiveDateErrors",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.productKeysWithExpiryEffectiveDateErrors
                                ? _c("upload-error-card", {
                                    attrs: {
                                      "error-message": _vm.$t(
                                        "engineInputs.upload.expiryEffectiveDateErrors"
                                      ),
                                      details:
                                        _vm.uploadError
                                          .productKeysWithExpiryEffectiveDateErrors,
                                      "unique-key-prefix":
                                        "expiryEffectiveDateErrors",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.productKeysWithInvalidPromoDiscount
                                ? _c("upload-error-card", {
                                    attrs: {
                                      "error-message": _vm.$t(
                                        "engineInputs.upload.invalidPromoDiscount"
                                      ),
                                      details:
                                        _vm.uploadError
                                          .productKeysWithInvalidPromoDiscount,
                                      "unique-key-prefix":
                                        "invalidPromoDiscount",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "primary",
                              on: {
                                click: function ($event) {
                                  return _vm.closeModal()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.close")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }