'use strict';

// When these are logged in the audit log, the event name is split by ":".
// The last part is considered the event name, while the earlier ones are considered entities
// and the audit log will look for its ids in the event payload.

module.exports = {
  user: {
    login: 'user:login',
    logout: 'user:logout',
    tokenRefreshed: 'user:token-refreshed',
    timeout: 'user:timeout',
  },
  workpackage: {
    exportResults: 'workpackage:export-results',
    create: 'workpackage:create',
    delete: 'workpackage:delete',
    update: 'workpackage:update',
    transfer: 'workpackage:transfer',
  },
  gridView: {
    engineRun: 'gridView:engine-run', // single PG
    engineRunAll: 'gridView:engine-run-all',
    engineRunPgs: 'gridView:engine-run-pgs', // unit & category level
  },
  generic: {
    backgroundTask: 'node:background-task',
    engineTriggered: 'generic:engine-triggered',
  },
  wholesale: {
    engineRun: 'wholesale:engine-run',
    exportResults: 'wholesale:export-results',
    ftpExport: 'wholesale:ftp-export',
  },
};
