<template>
  <div class="input-screen-page-wrapper" style="width: 100%; height: 100%">
    <!-- Menu options -->

    <inputs-grid
      ref="inputsGrid"
      :additional-columns="additionalColumns"
      :column-validations="columnValidations"
      :additional-grid-options="additionalGridOptions"
      :save-changes="saveChanges"
      :export-action="downloadEngineInputs"
      :is-exporting="downloading"
      route-suffix="engine"
    >
      <!-- Slots for inputs-grid, -->
      <!-- Import/ Add buttons etc. -->
      <template #buttons>
        <engine-input-upload v-if="canEditAttributes" />
      </template>
    </inputs-grid>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { get, startCase, mapValues, each, pick } from 'lodash';
import featureFlagsMixin from '../../../mixins/featureFlags';
import mandatoryEngineInputs from '@enums/mandatory-engine-inputs';
import numberFormats from '@enums/number-formats';
import {
  useZones,
  displayHierarchyAndFilter,
  allowTemplateIntentionCostChange,
} from '@enums/feature-flags';
import { yearMonthDayFormat } from '@enums/date-formats';
import DataTypes from '@enums/data-types';
import inputScreensTableMixin from '../../../mixins/inputScreensTable';
import tablesMixin from '../../../mixins/tables';
import { inputTableCssClasses } from '@enums/tables';
import { categoryLevel, pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';
import { maxDateTimestamp } from '@enums/dates';
import { isSameOrAfter } from '@sharedModules/data/utils/dates-utils';
import datePickerEditor from '../price-overrides/components/date-picker-editor.vue';
import {
  validations,
  percentageNumericCol,
  localisedNumericCol,
} from '../../../utils/inputs-grid-utils';

const percentageValidations = pick(
  validations,
  'mustBeValidNumber',
  'lessThanOrEqual100',
  'greaterThan0'
);

export default {
  name: 'EngineInputsEditor',
  components: {
    // register vue components
    // eslint thinks that it's not used, but headerComponent points to it
    // eslint-disable-next-line vue/no-unused-components
    datePickerEditor,
  },
  mixins: [featureFlagsMixin, inputScreensTableMixin, tablesMixin],

  async beforeRouteLeave(destination, from, next) {
    const preventNavigation = await this.$refs.inputsGrid.resolveEditsBeforeNavigation();

    if (preventNavigation) return;

    next();
  },

  data() {
    return {
      get,
      startCase,
      additionalGridOptions: {
        getMainMenuItems: this.getColumnMenuItems,
      },
      maxMomentDate: moment.utc(maxDateTimestamp),
      maxDateChecksMap: new Map(),
      // ColId must match
      columnValidations: {
        'mandatoryEngineInputs::targetMargin': percentageValidations,
        'mandatoryEngineInputs::promoParticipationSales': percentageValidations,
        'mandatoryEngineInputs::promoDiscount': pick(
          validations,
          'mustBeValidNumber',
          'lessThan100',
          'greaterThan0'
        ),
        'mandatoryEngineInputs::promoFunding': percentageValidations,
      },
      dateColumnTranslations: {
        effectiveDate: this.$t('engineInputs.editor.effectiveDate'),
        expiryDate: this.$t('engineInputs.editor.expiryDate'),
      },
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('engineInputs', ['downloading']),
    ...mapState('hierarchy', {
      loadingHierarchy: 'loading',
      busyImportingGroup: 'busyImportingGroup',
    }),
    ...mapState('competitorMetadata', ['competitors', 'competitorKeyToTypeMap']),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapGetters('workpackages', ['isSelectedWorkpackageMaster']),
    ...mapState('filters', ['retailAttributesFilter']),
    ...mapState('clientConfig', ['exportConfigs', 'dateFormats', 'i18nconfig']),

    zonesEnabled() {
      return this.isFeatureFlagEnabled(useZones);
    },

    fixedColumnsClass() {
      return this.toolStoreGroupColumn
        ? inputTableCssClasses.threeFixedColumns
        : inputTableCssClasses.twoFixedColumns;
    },

    showHierarchy() {
      return this.isFeatureFlagEnabled(displayHierarchyAndFilter);
    },

    canEditAttributes() {
      return this.isPricingSpecialist;
    },

    futureCostEditDisabled() {
      return (
        this.isSelectedWorkpackageMaster &&
        !this.isFeatureFlagEnabled(allowTemplateIntentionCostChange)
      );
    },

    exportTranslationMap() {
      const hierarchyColumnTranslations = this.showHierarchy
        ? {
            [`hierarchy.${categoryLevel}.levelEntryDescription`]: this.$t('pricing.category'),
            [`hierarchy.${pricingGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.pricingGroup'
            ),
            [`hierarchy.${architectureGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.architectureGroup'
            ),
          }
        : {};

      const toolStoreGroupColumn = this.zonesEnabled
        ? { toolStoreGroupKey: this.$t('pricing.toolStoreGroup') }
        : {};
      return {
        productKeyDisplay: this.$t('engineInputs.editor.productKey'),
        ...toolStoreGroupColumn,
        productName: this.$t('engineInputs.editor.productDescription'),
        ...hierarchyColumnTranslations,
        ...mapValues(mandatoryEngineInputs, path => this.$t(path)),
        ...this.dateColumnTranslations,
      };
    },

    hierarchyHeaders() {
      return this.showHierarchy
        ? [
            {
              text: this.$t('pricing.category'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${categoryLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.pricingGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${pricingGroupLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.architectureGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${architectureGroupLevel}.levelEntryDescription`,
              class: 'border-right m-width',
              dataType: DataTypes.str,
            },
          ]
        : [];
    },

    toolStoreGroupColumn() {
      return this.isFeatureFlagEnabled(useZones)
        ? {
            ...this.toolStoreGroupColumnDefaultProperties,
            text: this.$t('engineInputs.editor.toolStoreGroup'),
            class: `m-width ${inputTableCssClasses.threeFixedColumns}`,
          }
        : null;
    },

    headers() {
      const tsgHeader = this.toolStoreGroupColumn ? [this.toolStoreGroupColumn] : [];
      return [
        {
          text: this.$t('engineInputs.editor.productKey'),
          align: 'start',
          sortable: true,
          value: 'productKeyDisplay',
          class: `s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
          formatter: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
        ...tsgHeader,
        {
          text: this.$t('engineInputs.editor.productDescription'),
          align: 'start',
          sortable: true,
          value: 'productName',
          class: `l-width border-right ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
        ...this.hierarchyHeaders,
        {
          text: this.$t('engineInputs.editor.targetMargin'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.targetMargin',
          unit: '%',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.creationCost'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.creationCost',
          unit: this.$t('clientCurrencySymbol'),
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.goLiveCost'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.nonPromoNetCost',
          unit: this.$t('clientCurrencySymbol'),
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.promoParticipationSales'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.promoParticipationSales',
          unit: '%',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.promoDiscount'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.promoDiscount',
          unit: '%',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.promoFunding'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.promoFunding',
          unit: '%',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.intentionCost'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.intentionCost.price',
          unit: this.$t('clientCurrencySymbol'),
          dataType: DataTypes.number,
          deprecated: true,
        },
      ];
    },

    getTodaysDate() {
      return moment().format(yearMonthDayFormat);
    },

    additionalColumns() {
      return [
        {
          headerName: this.$t('linkText.engineInputs'),
          groupId: `mandatoryEngineInputs`,
          children: [
            this.createPercentageColumn('targetMargin'),
            {
              headerName: `${this.$t('engineInputs.editor.creationCost')} (${this.$t(
                'clientCurrencySymbol'
              )})`,
              headerTooltip: `${this.$t('engineInputs.editor.creationCost')} (${this.$t(
                'clientCurrencySymbol'
              )})`,
              colId: `mandatoryEngineInputs::creationCost`,
              field: `mandatoryEngineInputs.creationCost`,
              editable: true, // TODO: only allow editing if enabled is true
              tooltipValueGetter: this.tooltipValueGetter,
              ...localisedNumericCol,
            },
            {
              headerName: `${this.$t('engineInputs.editor.goLiveCost')} (${this.$t(
                'clientCurrencySymbol'
              )})`,
              headerTooltip: `${this.$t('engineInputs.editor.goLiveCost')} (${this.$t(
                'clientCurrencySymbol'
              )})`,
              colId: `mandatoryEngineInputs::nonPromoNetCost`,
              field: `mandatoryEngineInputs.nonPromoNetCost`,
              tooltipValueGetter: this.tooltipValueGetter,
              ...localisedNumericCol,
            },
            this.createPercentageColumn('promoParticipationSales'),
            this.createPercentageColumn('promoDiscount'),
            this.createPercentageColumn('promoFunding'),
            {
              headerName: `${this.$t('engineInputs.editor.intentionCost')} (${this.$t(
                'clientCurrencySymbol'
              )})`,
              headerTooltip: `${this.$t('engineInputs.editor.intentionCost')} (${this.$t(
                'clientCurrencySymbol'
              )})`,
              colId: `mandatoryEngineInputs::intentionCost::price`,
              field: `mandatoryEngineInputs.intentionCost.price`,
              editable: !this.futureCostEditDisabled,
              tooltipValueGetter: this.tooltipValueGetter,
              ...localisedNumericCol,
            },
            ...(!this.futureCostEditDisabled
              ? [
                  {
                    headerName: this.$t('engineInputs.editor.effectiveDate'),
                    colId: `mandatoryEngineInputs::intentionCost::effectiveDate`,
                    field: `mandatoryEngineInputs.intentionCost.effectiveDate`,
                    valueFormatter: params =>
                      this.maxDateExceeded(params.value)
                        ? '-'
                        : this.formatLocalisedDate(params.value),
                    cellEditor: 'datePickerEditor',
                    cellEditorPopup: true,
                    cellEditorPopupPosition: 'under',
                    cellEditorParams: {
                      firstDayOfWeek: this.i18nconfig.firstDayOfTheWeek,
                      minDate: () => moment('2000-01-01').format(yearMonthDayFormat),
                      locale: this.i18nconfig.fallbackLocale,
                      dateValue: date => date,
                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                      comparator: (filterLocalDateAtMidnight, cellValue) => {
                        if (cellValue == null) return -1;
                        const cellDate = moment(cellValue, yearMonthDayFormat);
                        const filterDate = moment(
                          filterLocalDateAtMidnight.toISOString().split('T')[0],
                          yearMonthDayFormat
                        ).add({ days: 1 });
                        if (filterDate.isSame(cellDate)) {
                          return 0;
                        }
                        if (cellDate.isBefore(filterDate)) {
                          return -1;
                        }
                        if (cellDate.isAfter(filterDate)) {
                          return 1;
                        }
                      },
                      browserDatePicker: true,
                      minValidYear: 2000,
                    },
                    tooltipValueGetter: this.tooltipValueGetter,
                    editable: !this.futureCostEditDisabled,
                  },
                  {
                    headerName: this.$t('engineInputs.editor.expiryDate'),
                    colId: `mandatoryEngineInputs::intentionCost::expiryDate`,
                    field: `mandatoryEngineInputs.intentionCost.expiryDate`,
                    valueFormatter: params =>
                      this.maxDateExceeded(params.value)
                        ? '-'
                        : this.formatLocalisedDate(params.value),
                    cellEditor: 'datePickerEditor',
                    cellEditorPopup: true,
                    cellEditorPopupPosition: 'under',
                    cellEditorParams: {
                      firstDayOfWeek: this.i18nconfig.firstDayOfTheWeek,
                      minDate: params => {
                        return moment(
                          params.data.mandatoryEngineInputs.intentionCost.effectiveDate
                        ).format(yearMonthDayFormat);
                      },
                      locale: this.i18nconfig.fallbackLocale,
                      dateValue: date => date,
                      pickerDate: params => {
                        return this.maxDateExceeded(params.value) ? this.getTodaysDate : undefined;
                      },
                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                      comparator: (filterLocalDateAtMidnight, cellValue) => {
                        if (cellValue == null) return -1;
                        const cellDate = moment(cellValue, yearMonthDayFormat);
                        const filterDate = moment(
                          filterLocalDateAtMidnight.toISOString().split('T')[0],
                          yearMonthDayFormat
                        ).add({ days: 1 });
                        if (filterDate.isSame(cellDate)) {
                          return 0;
                        }
                        if (cellDate.isBefore(filterDate)) {
                          return -1;
                        }
                        if (cellDate.isAfter(filterDate)) {
                          return 1;
                        }
                      },
                      browserDatePicker: true,
                      minValidYear: 2000,
                    },
                    tooltipValueGetter: this.tooltipValueGetter,
                    editable: !this.futureCostEditDisabled,
                  },
                ]
              : []),
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions('engineInputs', ['downloadItems']),

    tooltipValueGetter(params) {
      return this.$refs.inputsGrid.tooltipValueGetter(params);
    },

    isMainbanner(toolStoreGroupKey) {
      // If not using zones, you only have mainbanner
      if (!this.zonesEnabled) return true;

      return toolStoreGroupKey === this.mainTsgKey;
    },

    createPercentageColumn(field, overrides = {}) {
      return {
        headerName: `${this.$t(`engineInputs.editor.${field}`)} (%)`,
        colId: `mandatoryEngineInputs::${field}`,
        field: `mandatoryEngineInputs.${field}`,
        tooltipValueGetter: this.tooltipValueGetter,
        ...percentageNumericCol,
        ...overrides,
      };
    },

    getFormatters() {
      const clientDateFormat = this.dateFormats.clientDateFormatForMoment;
      const columnFormatters = this.getColumnFormatters(this.headers);
      each(this.dateColumnTranslations, column => {
        columnFormatters[column] = {
          type: DataTypes.date,
          format: clientDateFormat,
        };
      });
      return columnFormatters;
    },

    downloadEngineInputs() {
      this.downloadItems({
        where: this.retailAttributesFilter,
        translationMap: this.exportTranslationMap,
        pick: this.getColumnExportPickOptions(this.toolStoreGroupColumn),
        columnFormatters: this.getFormatters(),
      });
    },

    maxDateExceeded(date) {
      if (this.maxDateChecksMap.has(date)) {
        return this.maxDateChecksMap.get(date);
      }
      const momentDate = moment.utc(date);
      if (!momentDate.isValid()) {
        this.maxDateChecksMap.set(date, false);
        return false;
      }
      const maxDateExceeded = isSameOrAfter(momentDate, this.maxMomentDate);
      this.maxDateChecksMap.set(date, maxDateExceeded);
      return maxDateExceeded;
    },

    async saveChanges({ updates, mainTsgKey }) {
      const { data: results } = await axios.post(
        `/api/inputs/workpackage/${this.selectedWorkpackage._id}/engine`,
        { updates, mainTsgKey }
      );
      return results;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.main-header th {
  position: relative;
}

#buttons-bar {
  max-width: 20rem;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.date-attribute-cell {
  position: relative;
}

.invalid-blocking {
  border-bottom: 0.2rem solid #c13939 !important;
}

.pale {
  opacity: 0.7;
}
</style>
