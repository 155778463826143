<template>
  <tr class="hierarchy__row hierarchy__row--headers">
    <th class="border-right first-column">
      <v-row justify="end" no-gutters class="flex-nowrap">
        <feature-toggle :toggle="displayHistoricalDropdown">
          <v-col cols="3" class="text-right flex-shrink-1 mr-3 header__historical-periods">
            <v-select
              :value="selectedPeriod"
              :items="historicPeriodsOptions"
              :label="$t(`gridView.historicPeriods.label`)"
              :disabled="timeFlexibleAggregationsLoading"
              :loading="timeFlexibleAggregationsLoading"
              single-line
              dense
              hide-details
              class="float-right mt-0"
              @input="setSelectedHistoricPeriod"
            />
          </v-col>
        </feature-toggle>
        <v-col cols="1" class="flex-grow-1 align-self-center header__toggles">
          <!-- FEATURE_FLAG: the toggle for switching between weightings can be removed -->
          <feature-toggle :toggle="displayWeightToggle">
            <v-switch
              :label="$t(`gridView.weightings.${weightingOptions[1]}`)"
              :input-value="selectedWeighting"
              :true-value="weightingOptions[1]"
              :false-value="weightingOptions[0]"
              primary
              hide-details
              @change="toggleCalculation"
            />
          </feature-toggle>
          <v-switch
            :label="$t(`gridView.regularImpactToggle.label`)"
            :input-value="showRegularImpact"
            primary
            hide-details
            class="pb-1"
            @change="toggleRegularImpact"
          />
        </v-col>
      </v-row>
    </th>
    <th class="fixed-width-number">{{ $t('gridView.salesMAT') }}</th>
    <th class="fixed-width-number">{{ $t('gridView.promoShare') }}</th>
    <th class="fixed-width-number">
      {{ $t(`gridView.${showRegularImpact ? 'regularSalesImpact' : 'salesImpactMAT'}`) }}
    </th>
    <th class="fixed-width-number border-right">{{ $t('gridView.totalCostImpact') }}</th>
    <th class="fixed-width-number">{{ $t('gridView.proposedMargin') }}</th>
    <th class="fixed-width-number border-right">{{ $t('gridView.delta') }}</th>
    <th class="fixed-width-number">{{ $t('gridView.target') }}</th>
    <th class="fixed-width-number">{{ $t('gridView.live') }}</th>
    <th class="fixed-width-number">{{ $t('gridView.proposed') }}</th>
    <th class="fixed-width-number border-right">{{ $t('gridView.delta') }}</th>
    <th class="fixed-width-number">{{ $t('gridView.target') }}</th>
    <th class="fixed-width-number">{{ $t('gridView.live') }}</th>
    <th class="fixed-width-number">{{ $t('gridView.proposed') }}</th>
    <th class="fixed-width-number border-right">{{ $t('gridView.delta') }}</th>
    <th class="fixed-width-number border-right">{{ $t(tensionLabelTranslation) }}</th>
    <th class="fixed-width-chip" />
    <th />
  </tr>
</template>

<script>
import { keys, values, invert } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { useZones, displayWeightToggle, displayHistoricalDropdown } from '@enums/feature-flags';
import timeFlexibleEndings from '@enums/time-flexible-endings';
import { yearly } from '@enums/historical-periods';
import featureFlagsMixin from '../../../mixins/featureFlags';

export default {
  mixins: [featureFlagsMixin],

  data() {
    return {
      displayWeightToggle,
      displayHistoricalDropdown,
    };
  },

  computed: {
    ...mapState('gridView', [
      'selectedWeighting',
      'showRegularImpact',
      'selectedHistoricalPeriod',
      'alertFilter',
    ]),
    ...mapState('clientConfig', ['toggleLogic']),
    ...mapGetters('gridView', ['timeFlexibleAggregationsLoading']),

    selectedPeriod() {
      return this.historicPeriodsMap[this.selectedHistoricalPeriod];
    },

    historicPeriodsMap() {
      // mapping of timeFlexibleEndings and FE translations
      const translationMap = keys(timeFlexibleEndings).reduce((acc, ending) => {
        const direction = ending.toLowerCase().includes('forward') ? 'forward' : 'back';
        const number = parseInt(ending.replace(/[^0-9]/g, ''), 10);
        acc[ending] = this.$tc(`gridView.historicPeriods.${direction}`, number, { number });
        return acc;
      }, {});
      translationMap[yearly] = this.$t(`gridView.historicPeriods.${yearly}`);
      return translationMap;
    },

    historicPeriodsOptions() {
      const yearlyTranslation = this.$t(`gridView.historicPeriods.${yearly}`);
      const historicPeriods = values(this.historicPeriodsMap).filter(
        item => item !== yearlyTranslation
      );
      historicPeriods.unshift(yearlyTranslation);
      return historicPeriods;
    },

    weightingOptions() {
      return this.toggleLogic.competitorIndexWeightings;
    },

    tensionLabelTranslation() {
      return this.isFeatureFlagEnabled(useZones)
        ? 'gridView.tensionLabelUseZones'
        : 'gridView.tensionLabel';
    },
  },

  methods: {
    ...mapActions('gridView', [
      'setSelectedWeighting',
      'setShowRegularImpact',
      'setSelectedHistoricalPeriod',
      'setAlertFilter',
    ]),

    toggleCalculation(selectedWeighting) {
      this.setSelectedWeighting({ selectedWeighting });
    },

    toggleRegularImpact(showRegularImpact) {
      this.setShowRegularImpact({ showRegularImpact });
    },

    setSelectedHistoricPeriod(newSelectedPeriod) {
      if (newSelectedPeriod === this.selectedPeriod) {
        return;
      }
      const selectedHistoricalPeriod = invert(this.historicPeriodsMap)[newSelectedPeriod];
      if (
        this.alertFilter &&
        this.alertFilter.includes('missingHistoricalData') &&
        this.alertFilter !== `missingHistoricalData${selectedHistoricalPeriod}`
      ) {
        this.setAlertFilter(null);
      }
      this.setSelectedHistoricalPeriod({ selectedHistoricalPeriod });
      this.globalEmit('historical-period-selection-changed');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.hierarchy__row--headers > td {
  font-size: 1.2rem;
  vertical-align: bottom;
}

th {
  vertical-align: bottom;
  font-size: 1rem;
  font-weight: 300;
}

.header {
  &__historical-periods,
  &__toggles {
    min-width: 15rem;
  }

  &__toggles {
    ::v-deep .v-input--switch .v-label {
      font-size: 1.2rem;
    }
  }
}
</style>

<style lang="scss">
.v-list--dense .v-list-item .v-list-item__title {
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.5rem;
}
</style>
