import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid-view-container" },
    [
      _c(
        VDataTable,
        {
          ref: "dataTable",
          staticClass: "grid-view",
          class: {
            "colored-architecture-groups":
              _vm.isSortByArchitectureGroupsEnabled ||
              _vm.isSortByArchitectureSubGroupsEnabled,
            "colored-architecture-sub-groups":
              _vm.isSortByArchitectureSubGroupsEnabled,
          },
          attrs: {
            options: _vm.pagination,
            "sort-desc": _vm.pagination.descending,
            items: _vm.items,
            headers: _vm.headers,
            search: _vm.search,
            "custom-sort": _vm.customSort,
            "custom-filter": _vm.customFilter,
            "single-expand": true,
            loading: _vm.itemsNotReadyForDisplay,
            "item-key": "_id",
            "hide-default-footer": "",
            "hide-default-header": "",
          },
          on: {
            "update:options": function ($event) {
              _vm.pagination = $event
            },
            "update:sortDesc": function ($event) {
              return _vm.$set(_vm.pagination, "descending", $event)
            },
            "update:sort-desc": function ($event) {
              return _vm.$set(_vm.pagination, "descending", $event)
            },
            "update:page": function ($event) {
              return _vm.$vuetify.goTo(_vm.$refs.dataTable)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function () {
                return [
                  _c("table", [
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                id: "download-button",
                                loading: _vm.downloadingItems,
                                color: "primary",
                                small: "",
                                depressed: "",
                              },
                              on: { click: _vm.downloadData },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("actions.download")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "align-self-center summary-text" },
                            [_vm._v(_vm._s(_vm.rowSummary) + " ")]
                          ),
                          _vm._v(" "),
                          _vm.items < _vm.totalRows ? _vm._m(0) : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "alert-row-cell" }),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "header",
              fn: function ({ props }) {
                return [
                  _c("tr", { staticClass: "main-header border-bottom" }, [
                    _c(
                      "th",
                      {
                        staticClass: "border-right",
                        attrs: { colspan: _vm.showToolStoreGroup ? 6 : 5 },
                      },
                      [
                        _c(
                          VRow,
                          [
                            _c(
                              VCol,
                              { staticClass: "col pt-0 pb-0" },
                              [
                                _c(VTextField, {
                                  staticClass: "search-box pt-0",
                                  attrs: {
                                    value: _vm.search,
                                    "append-icon": "search",
                                    "hide-details": true,
                                    label: _vm.$t("actions.search"),
                                    "single-line": "",
                                    loading: _vm.loading,
                                  },
                                  on: { input: _vm.debounceSearchUpdate },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  { staticClass: "pt-0" },
                                  [
                                    _c(
                                      VRow,
                                      [
                                        _c(
                                          VCol,
                                          { staticClass: "col pt-0" },
                                          [
                                            _c(
                                              VRow,
                                              [
                                                !_vm.isUnitManagerView
                                                  ? _c(VSwitch, {
                                                      staticClass:
                                                        "mr-2 pt-0 sort-by-architecture-groups-switcher",
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "gridView.sortByArchitectureGroups"
                                                        ),
                                                        primary: "",
                                                        "data-test-id":
                                                          "sort-by-architecture-groups-switcher",
                                                        dense: "",
                                                        "hide-details": "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.toggleSortingByArchitectureGroups,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.isSortByArchitectureGroupsEnabled,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.isSortByArchitectureGroupsEnabled =
                                                            $$v
                                                        },
                                                        expression:
                                                          "isSortByArchitectureGroupsEnabled",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              VRow,
                                              [
                                                !_vm.isUnitManagerView
                                                  ? _c(VSwitch, {
                                                      staticClass:
                                                        "mr-2 pt-0 sort-by-architecture-groups-switcher",
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "gridView.sortByArchitectureSubGroups"
                                                        ),
                                                        primary: "",
                                                        "data-test-id":
                                                          "sort-by-architecture-sub-groups-switcher",
                                                        dense: "",
                                                        "hide-details": "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.toggleSortingByArchitectureSubGroups,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.isSortByArchitectureSubGroupsEnabled,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.isSortByArchitectureSubGroupsEnabled =
                                                            $$v
                                                        },
                                                        expression:
                                                          "isSortByArchitectureSubGroupsEnabled",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  VCol,
                                  { staticClass: "pt-0 mt-2" },
                                  [
                                    _c("norm-weight-toggle", {
                                      staticClass: "norm-weight-toggle",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "border-right text-left",
                        attrs: { colspan: "3" },
                      },
                      [_vm._v(_vm._s(_vm.$t("pricing.mainHeaders.sales")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "border-right text-left",
                        attrs: { colspan: "2" },
                      },
                      [_vm._v(_vm._s(_vm.$t("pricing.mainHeaders.cost")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "border-right text-left",
                        attrs: { colspan: "4" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("pricing.mainHeaders.prices")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        class: [
                          "border-right",
                          "text-left",
                          "border-left-primary",
                          "scenario-colour",
                        ],
                        attrs: { colspan: "6" },
                      },
                      [
                        _vm.isUnitManagerView
                          ? _c(VRow, { attrs: { "no-gutters": "" } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "pricing.mainHeaders.proposedPricing"
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _c(
                              VRow,
                              { attrs: { "no-gutters": "" } },
                              [
                                _c("scenario-filter", {
                                  attrs: {
                                    "hide-pricing-group-selection": true,
                                    dense: false,
                                    solo: false,
                                    "hide-label": true,
                                  },
                                  on: { selectScenario: _vm.selectScenario },
                                }),
                                _vm._v(" "),
                                _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      value: _vm.engineBlockedMsg,
                                      position: "top-left",
                                      disabled: _vm.engineCanRun,
                                    },
                                  },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "mr-2 btn-refresh",
                                        attrs: {
                                          depressed: "",
                                          color: "primary",
                                          loading:
                                            _vm.calculatingScenarioResults,
                                          disabled:
                                            !_vm.isEditable ||
                                            !_vm.engineCanRun,
                                          "data-dd-action-name":
                                            "Grid view recalculate all",
                                        },
                                        on: { click: _vm.recalculate },
                                      },
                                      [_vm._m(1)],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-3 mr-3 pull-right" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.lastTriggered) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "border-right text-left",
                        attrs: { colspan: "4" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("pricing.mainHeaders.competitors")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(_vm._s(_vm.$t("pricing.mainHeaders.tension"))),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "alert-padding" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tr",
                    { staticClass: "sub-header v-data-table-header" },
                    _vm._l(props.headers, function (header) {
                      return _c(
                        "th",
                        {
                          key: header.key || header.valuePath,
                          class: [
                            "column text-xs-left",
                            header.sortable ? "sortable" : "",
                            _vm.pagination.descending ? "desc" : "asc",
                            header.valuePath === _vm.pagination.sortBy[0]
                              ? "active"
                              : "",
                            header.class,
                          ],
                          style: header.style,
                        },
                        [
                          header.hasOwnProperty("competitorHeader")
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.selectedCompetitors[
                                        header.competitorHeader
                                      ].competitorDisplayDescription
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _c(
                                "span",
                                {
                                  attrs: {
                                    "data-dd-action-name":
                                      "Sort grid view table column",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSort(header)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t(header.text)) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              ),
                          _vm._v(" "),
                          header && header.sortable
                            ? _c(
                                "div",
                                [
                                  _c("grid-view-filter-popover", {
                                    attrs: { header: header },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    VIcon,
                                    {
                                      staticClass: "v-data-table-header__icon",
                                      attrs: {
                                        size: "1.4rem",
                                        "data-dd-action-name":
                                          "Sort grid view table column",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeSort(header)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              arrow_upward\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              },
            },
            {
              key: "item",
              fn: function (expandableItem) {
                return [
                  _c(
                    "tr",
                    {
                      key: expandableItem.index,
                      class: [
                        "border-right",
                        {
                          "on-promo-row-color": _vm.itemOnPromotion(
                            expandableItem.item
                          ),
                        },
                      ],
                      on: {
                        click: function ($event) {
                          return expandableItem.expand(
                            !expandableItem.isExpanded
                          )
                        },
                      },
                    },
                    [
                      _c("td", {
                        staticClass: "architecture-group-cell",
                        class:
                          "color-" +
                          _vm.architectureGroupDescriptionToColorMap[
                            expandableItem.item.architectureGroupDescription
                          ],
                        attrs: {
                          "custom-title-msg":
                            expandableItem.item.architectureGroupDescription,
                          "custom-title-hidden":
                            !_vm.isSortByArchitectureGroupsEnabled,
                          "custom-title-position": "right",
                        },
                      }),
                      _vm._v(" "),
                      _c("td", {
                        staticClass: "architecture-sub-group-cell",
                        class:
                          "color-" +
                          _vm.get(
                            _vm.architectureSubGroupDescriptionToColorMap,
                            [
                              expandableItem.item.architectureGroupDescription,
                              expandableItem.item.subGroupDescription,
                            ]
                          ),
                        attrs: {
                          "custom-title-msg":
                            _vm.getSubGroupTitleMsg(expandableItem),
                          "custom-title-hidden":
                            !_vm.isSortByArchitectureSubGroupsEnabled,
                          "custom-title-position": "right",
                        },
                      }),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(expandableItem.item.productKeyDisplay)),
                      ]),
                      _vm._v(" "),
                      _vm.showToolStoreGroup
                        ? _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _c("tooltipped-truncated-field", {
                                attrs: {
                                  text: expandableItem.item
                                    .toolStoreGroupDescription,
                                  "truncation-length":
                                    _vm.truncationLength.toolStoreGroup,
                                  "tooltip-position": "right",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-xs-left font-weight-bold" },
                        [
                          _c("tooltipped-truncated-field", {
                            attrs: {
                              text: expandableItem.item.productDescription,
                              "truncation-length": _vm.truncationLength.article,
                              "tooltip-position": "right",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right border-right" },
                        [
                          _c("norm-weight-tooltip", {
                            attrs: {
                              value: expandableItem.item.productSizeType,
                              "norm-weight": expandableItem.item.normWeight,
                              "norm-weight-uo-m":
                                expandableItem.item.normWeightUnitOfMeasure,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number: expandableItem.item.yearlySales / 1000,
                                format: _vm.numberFormats.oneDecimalPlace,
                              })
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number: expandableItem.item.yearlyVolume / 1000,
                                format: _vm.numberFormats.oneDecimalPlace,
                              })
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right border-right",
                          attrs: {
                            "custom-title-position": "right",
                            "custom-title-msg": _vm.formatTooltipMessage({
                              [_vm.$t(
                                "gridView.popupText.promoParticipationSales"
                              )]: _vm.formatNumber({
                                number: _vm.formatEngineInput(
                                  expandableItem.item.mandatoryEngineInputs
                                    .promoParticipationSales
                                ),
                                format: _vm.numberFormats.percent,
                                nullAsDash: true,
                              }),
                              [_vm.$t("gridView.popupText.promoDiscount")]:
                                _vm.formatNumber({
                                  number: _vm.formatEngineInput(
                                    expandableItem.item.mandatoryEngineInputs
                                      .promoDiscount
                                  ),
                                  format: _vm.numberFormats.percent,
                                  nullAsDash: true,
                                }),
                              [_vm.$t("gridView.popupText.promoFunding")]:
                                _vm.formatNumber({
                                  number: _vm.formatEngineInput(
                                    expandableItem.item.mandatoryEngineInputs
                                      .promoFunding
                                  ),
                                  format: _vm.numberFormats.percent,
                                  nullAsDash: true,
                                }),
                            }),
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "right-aligned-text-inside-tooltip",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formatNumber({
                                      number: _vm.formatEngineInput(
                                        expandableItem.item
                                          .mandatoryEngineInputs
                                          .promoParticipationSales
                                      ),
                                      format: _vm.numberFormats.percent,
                                      nullAsDash: true,
                                    })
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number: _vm.getNonPromoNetCost(
                                  expandableItem.item
                                ),
                                format: _vm.numberFormats.priceFormat,
                                nullAsDash: true,
                              })
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right border-right",
                          class: [
                            _vm.formatNumberColourClass(
                              -expandableItem.item.costDelta
                            ),
                          ],
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getCostDelta(expandableItem.item)) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right chart pa-0" },
                        [
                          _c("sparkline-chart", {
                            attrs: {
                              "price-history-week":
                                expandableItem.item.priceHistoryWeek,
                              "intention-price":
                                expandableItem.item.intentionPrice.price,
                              options: _vm.chartOptions,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right font-weight-bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getLivePrice(expandableItem.item)) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          attrs: {
                            "custom-title-position": "right",
                            "custom-title-msg":
                              _vm.intentionPriceTooltipValue(expandableItem),
                            "custom-title-hidden":
                              !_vm.displayIntentionPriceTooltip(expandableItem),
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getFuturePrice(expandableItem.item)) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "text-right border-right font-weight-bold",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatNumber({
                                  number: expandableItem.item.intentionMargin,
                                  format: _vm.numberFormats.percent,
                                })
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          class: [
                            "text-right",
                            "pl-0",
                            "price-override",
                            "border-left-primary",
                            "border-right-primary",
                            "scenario-colour",
                          ],
                        },
                        [
                          _c(
                            VLayout,
                            {
                              class: _vm.getClassForOverride(
                                expandableItem.item
                              ),
                              attrs: { xs12: "" },
                              on: { click: _vm.preventExpand },
                            },
                            [
                              _c(
                                VFlex,
                                {
                                  staticClass: "text-xs-left",
                                  attrs: { xs3: "" },
                                },
                                [
                                  _vm.isEditable &&
                                  _vm.hasOverride(expandableItem.item) &&
                                  !_vm.isSmartOverride(expandableItem.item)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "revert-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRevertButtonClick(
                                                expandableItem.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._m(2)],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(VFlex, { attrs: { xs9: "" } }, [
                                expandableItem.item.scenarioPrice
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c("pricing-edit-text-field", {
                                          key: expandableItem.item._id,
                                          staticClass: "font-weight-bold pa-0",
                                          attrs: {
                                            "data-type": _vm.dataType.number,
                                            "number-format":
                                              _vm.numberFormats.priceFormat,
                                            value: _vm.getOverridePrice(
                                              expandableItem.item
                                            ),
                                            "previous-value":
                                              _vm.getPreviousValue(
                                                expandableItem.item
                                              ),
                                            error:
                                              _vm.updateErrors[
                                                expandableItem.item._id
                                              ],
                                            "cell-class":
                                              _vm.getOverrideFieldClass(
                                                expandableItem.item
                                              ),
                                            "tooltip-value":
                                              _vm.scenarioPriceTooltip(
                                                expandableItem.item
                                              ),
                                            "tooltip-position": "right",
                                            "previous-value-translation-key":
                                              "gridView.tooltips",
                                            disabled: !_vm.isEditable,
                                          },
                                          on: {
                                            focus: function ($event) {
                                              return _vm.debounceShowDatePicker(
                                                expandableItem.item
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.changeOverridePrice(
                                                $event.target.value,
                                                expandableItem.item
                                              )
                                            },
                                            dblclick: function ($event) {
                                              return _vm.cancelScenarioPrice(
                                                expandableItem.item
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.debounceRemoveIcon(
                                                expandableItem.item
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "override-container" },
                                          [
                                            _vm.isEditable &&
                                            _vm.shouldDisplayCalendar(
                                              expandableItem.item
                                            )
                                              ? _c(
                                                  VMenu,
                                                  {
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      "nudge-right": 5,
                                                      transition:
                                                        "scale-transition",
                                                      "offset-x": "",
                                                      top: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.closeCalendar(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "override-calendar-button",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      depressed:
                                                                        "",
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._m(3)],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c(
                                                      VDatePicker,
                                                      {
                                                        attrs: {
                                                          "first-day-of-week":
                                                            _vm.i18nconfig
                                                              .firstDayOfTheWeek,
                                                          min: _vm.today,
                                                          "no-title": "",
                                                          locale:
                                                            _vm.i18nconfig
                                                              .fallbackLocale,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectOverrideDates(
                                                              expandableItem.item,
                                                              $event
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .priceOverrideDates[
                                                              expandableItem
                                                                .item._id
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.priceOverrideDates,
                                                              expandableItem
                                                                .item._id,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "priceOverrideDates[expandableItem.item._id]",
                                                        },
                                                      },
                                                      [
                                                        _c(VSpacer),
                                                        _vm._v(" "),
                                                        _c(
                                                          VBtn,
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              outlined: "",
                                                              depressed: "",
                                                              small: "",
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removePriceLock(
                                                                  expandableItem.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "gridView.removePriceLock"
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [_vm._v("-")]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right scenario-colour" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.getScenarioPriceChange(expandableItem.item)
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right scenario-colour" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.getScenarioPriceChangeRatio(
                                expandableItem.item
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "text-right scenario-colour font-weight-bold",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatNumberEmptyCheck(
                                  expandableItem.item,
                                  "scenarioMargin",
                                  _vm.numberFormats.percent
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "text-right scenario-colour font-weight-bold",
                          class: [
                            _vm.formatNumberColourClass(
                              _vm.getSalesImpact(expandableItem.item)
                                ? _vm.getSalesImpact(expandableItem.item) / 1000
                                : 0
                            ),
                          ],
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getSalesImpact(expandableItem.item)
                                  ? _vm.formatNumber({
                                      number:
                                        _vm.getSalesImpact(
                                          expandableItem.item
                                        ) / 1000,
                                      format: _vm.numberFormats.oneDecimalPlace,
                                    })
                                  : "-"
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "text-right border-right scenario-colour font-weight-bold",
                          class: [
                            _vm.formatNumberColourClass(
                              _vm.getCostImpact(expandableItem.item)
                                ? _vm.getCostImpact(expandableItem.item) / 1000
                                : 0,
                              true
                            ),
                          ],
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getCostImpact(expandableItem.item)
                                  ? _vm.formatNumber({
                                      number:
                                        -_vm.getCostImpact(
                                          expandableItem.item
                                        ) / 1000,
                                      format: _vm.numberFormats.oneDecimalPlace,
                                    })
                                  : "-"
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right font-weight-bold",
                          attrs: {
                            "custom-title-max-size": "medium",
                            "custom-title-position": "right",
                            "custom-title-msg": _vm.formatTooltipMessage({
                              [_vm.$t("tooltip.competitorDescription")]:
                                expandableItem.item.competitor1
                                  .competitorProductDescription || "-",
                              [_vm.$t("tooltip.competitorSize")]:
                                _vm.formatNumber({
                                  number:
                                    expandableItem.item.competitor1
                                      .contentValue,
                                  format: _vm.numberFormats.priceFormat,
                                  zeroAsDash: true,
                                  nullAsDash: true,
                                }),
                              [_vm.$t("tooltip.competitorUnitOfMeasure")]:
                                expandableItem.item.competitor1
                                  .contentUnitOfMeasure || "-",
                              [_vm.$t("tooltip.grossReferencePrice")]:
                                _vm.formatNumber({
                                  number:
                                    expandableItem.item.competitor1
                                      .grossReferenceCompetitorPrice,
                                  format: _vm.numberFormats.priceFormat,
                                  zeroAsDash: true,
                                  nullAsDash: true,
                                }),
                            }),
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "right-aligned-text-inside-tooltip",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.getCompetitorPrice(
                                      expandableItem.item.competitor1
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          class: [
                            _vm.formatNumberColourClass(
                              expandableItem.item.competitor1.competitorIndex
                            ),
                          ],
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatNumberEmptyCheck(
                                  expandableItem.item.competitor1,
                                  "competitorIndex",
                                  _vm.numberFormats.percent
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right font-weight-bold",
                          attrs: {
                            "custom-title-position": "right",
                            "custom-title-max-size": "medium",
                            "custom-title-msg": _vm.formatTooltipMessage({
                              [_vm.$t("tooltip.competitorDescription")]:
                                expandableItem.item.competitor2
                                  .competitorProductDescription || "-",
                              [_vm.$t("tooltip.competitorSize")]:
                                _vm.formatNumber({
                                  number:
                                    expandableItem.item.competitor2
                                      .contentValue,
                                  format: _vm.numberFormats.priceFormat,
                                  zeroAsDash: true,
                                  nullAsDash: true,
                                }),
                              [_vm.$t("tooltip.competitorUnitOfMeasure")]:
                                expandableItem.item.competitor2
                                  .contentUnitOfMeasure || "-",
                              [_vm.$t("tooltip.grossReferencePrice")]:
                                _vm.formatNumber({
                                  number:
                                    expandableItem.item.competitor2
                                      .grossReferenceCompetitorPrice,
                                  format: _vm.numberFormats.priceFormat,
                                  zeroAsDash: true,
                                  nullAsDash: true,
                                }),
                            }),
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "right-aligned-text-inside-tooltip",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.getCompetitorPrice(
                                      expandableItem.item.competitor2
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right border-right",
                          class: [
                            _vm.formatNumberColourClass(
                              expandableItem.item.competitor2.competitorIndex
                            ),
                          ],
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatNumberEmptyCheck(
                                  expandableItem.item.competitor2,
                                  "competitorIndex",
                                  _vm.numberFormats.percent
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-xs-left chart" },
                        [
                          expandableItem.item.scenarioPrice
                            ? _c("tension-chart", {
                                attrs: {
                                  options: _vm.tensionChartOptions,
                                  "scenario-price":
                                    expandableItem.item.scenarioPrice,
                                  "economic-reference-price":
                                    expandableItem.item.economicReferencePrice,
                                  "competitor-reference-price":
                                    expandableItem.item
                                      .competitorReferencePrice,
                                  "architecture-reference-price":
                                    expandableItem.item
                                      .architectureReferencePrice,
                                  "store-group-reference-price":
                                    expandableItem.item
                                      .storeGroupReferencePrice,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "alert-cell" },
                        [
                          _c(
                            VRow,
                            { staticClass: "ml-0" },
                            [
                              _c(
                                VCol,
                                { staticClass: "pa-0", attrs: { cols: "6" } },
                                [
                                  _vm.canSeeAlerts &&
                                  expandableItem.item.filteredAlerts.length
                                    ? _c("product-alerts", {
                                        key: expandableItem.item._id,
                                        attrs: {
                                          alerts:
                                            expandableItem.item.filteredAlerts,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "pa-0", attrs: { cols: "6" } },
                                [
                                  _vm.itemHasUpcomingPromotion(
                                    expandableItem.item
                                  )
                                    ? _c(
                                        "tooltip",
                                        {
                                          attrs: {
                                            value:
                                              _vm.getFreezePromotionTooltip(
                                                expandableItem.item
                                              ),
                                            position: "top-left",
                                          },
                                        },
                                        [_vm._m(4)],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "expanded-item",
              fn: function (props) {
                return [
                  _c(
                    "td",
                    {
                      staticClass: "expanded-product-view-cell",
                      attrs: { colspan: _vm.headers.length - 1 },
                    },
                    [
                      _c("expanded-product-view", {
                        key: `${props.item.productKey}::${props.item.toolStoreGroupKey}`,
                        attrs: {
                          "product-key": String(props.item.productKey),
                          "pricing-group-id":
                            props.item.hierarchy[_vm.pricingGroupLevel].id,
                          "architecture-group-id":
                            props.item.hierarchy[_vm.architectureGroupLevel].id,
                          "architecture-sub-group-description":
                            props.item.subGroupDescription,
                          "scenario-key": props.item.scenarioKey,
                          "tool-store-group-key": props.item.toolStoreGroupKey,
                          "is-unit-manager-view": _vm.isUnitManagerView,
                        },
                        on: {
                          toggleFilteringByArchitectureGroup:
                            _vm.onToggleFilteringByArchitectureGroup,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "alert-row-cell" }),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _c("table", { staticClass: "no-results" }, [
                    _c("tr", [
                      _c("td", { attrs: { colspan: _vm.headers.length - 1 } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("product.noData")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "alert-row-cell" }),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "no-results",
              fn: function () {
                return [
                  _c("table", { staticClass: "no-results" }, [
                    _c("tr", [
                      _c("td", { attrs: { colspan: _vm.headers.length - 1 } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("product.noResults", {
                                search: _vm.search,
                              })
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "alert-row-cell" }),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "loading",
              fn: function () {
                return [
                  _c("table", { staticClass: "no-results" }, [
                    _c("tr", [
                      _c("td", { attrs: { colspan: _vm.headers.length - 1 } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.loadingMessage")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "alert-row-cell" }),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "progress",
              fn: function () {
                return [
                  _c("table", { staticClass: "no-results" }, [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "progress-cell-grid-view",
                          attrs: { colspan: _vm.headers.length - 1 },
                        },
                        [
                          _c(VProgressLinear, {
                            staticClass: "progress-bar-grid-view",
                            attrs: {
                              absolute: "",
                              active: _vm.itemsNotReadyForDisplay,
                              color: "primary",
                              height: "0.2rem",
                              indeterminate: _vm.itemsNotReadyForDisplay,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "alert-row-cell" }),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _c("template", { slot: "footer" }, [
            _c("table", { staticClass: "product-table-footer" }, [
              _c("tr", [
                _c(
                  "td",
                  [
                    _c(
                      VRow,
                      { attrs: { justify: "space-between" } },
                      [
                        _c(
                          VCol,
                          { attrs: { md: "3", "offset-md": "9" } },
                          [
                            _c(VPagination, {
                              attrs: { length: _vm.pages },
                              model: {
                                value: _vm.pagination.page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "page", $$v)
                                },
                                expression: "pagination.page",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "alert-row-cell" }),
              ]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      {
        attrs: { small: "", color: "primary" },
        on: {
          click: function ($event) {
            return _vm.removeActiveTableFilter(_vm.activeTableFilters)
          },
        },
      },
      [_vm._v("\n              delete\n            ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, [_vm._v("mdi-refresh")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { "x-small": "", dark: "" } }, [
      _vm._v("fa fa-undo"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { small: "" } }, [_vm._v(" event ")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, [_vm._v(" $promotion-pr ")])
  },
]
render._withStripped = true

export { render, staticRenderFns }