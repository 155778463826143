import { render, staticRenderFns } from "./diagnostics.vue?vue&type=template&id=3dbee032&scoped=true"
import script from "./diagnostics.vue?vue&type=script&lang=js"
export * from "./diagnostics.vue?vue&type=script&lang=js"
import style0 from "./diagnostics.vue?vue&type=style&index=0&id=3dbee032&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbee032",
  null
  
)

export default component.exports